import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import './UsuarioNovo.css'
import criarNovoUsuario from '../../services/usuario/usuario_novo'

function ContaColunaNovo() {
  const [login, setLogin] = useState('')
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [ativo, setAtivo] = useState(false)
  const [perfil, setPerfil] = useState('')
  const [restricoes, setRestricoes] = useState(false)

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      name === 'ativo' ? setAtivo(checked) : setRestricoes(checked)
    } else {
      switch (name) {
        case 'login':
          setLogin(value)
          break
        case 'nome':
          setNome(value)
          break
        case 'email':
          setEmail(value)
          break
        case 'password':
          setPassword(value)
          break
        case 'perfil':
          setPerfil(value)
          break
        default:
          break
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      // Exibe a mensagem de erro para o campo de e-mail
      alert('Por favor, insira um e-mail válido.');
      return;
    }
    if (!login || !nome || !email || !password || !perfil) {
      // Exibe a mensagem de erro
      alert('Por favor, preencha todos os campos.');
      return;
    }else{
    
    const data = {
      login: login,
      nome: nome,
      email: email,
      password: password,
      ativo: ativo,
      perfil: perfil,
      restringir_usuario: restricoes
    };
    criarNovoUsuario(data);
  }
  };
  const handleVoltar = () => {
    window.location = '/configuracao/usuario'
  }

  return (
    <div className='configuracao-usuario-novo-div-form'>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className='bold'>Login</Form.Label>
        <Form.Control type="text" name="login" value={login} onChange={handleChange} placeholder="Login" required />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
        <Form.Label className='bold'>Nome</Form.Label>
        <Form.Control type="text" name="nome" value={nome} onChange={handleChange} placeholder="Nome" required />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
        <Form.Label className='bold'>E-mail</Form.Label>
        <Form.Control type="email" name="email" value={email} onChange={handleChange} placeholder="E-mail" required />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
        <Form.Label className='bold'>Senha</Form.Label>
        <Form.Control type="password" name="password" value={password} onChange={handleChange} placeholder="Senha" required />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
        <Form.Check type="checkbox" name="ativo" checked={ativo} onChange={handleChange} label={'Ativo'} />
      </Form.Group>
      <Form.Label className='bold mb-3'>Perfil</Form.Label>
      <Form.Select className='mb-3' name="perfil" value={perfil} onChange={handleChange}>
        <option hidden value=""></option>
        <option value="1">Administrador</option>
        <option value="2">Gerente</option>
        <option value="3">Caixa</option>
      </Form.Select>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
        <Form.Check type="checkbox" name="restricoes" checked={restricoes} onChange={handleChange} label={'Restringir Usuário'} />
      </Form.Group>
      <Button className='usuarionovo-button-criar' onClick={handleSubmit}>Criar</Button>
      <Button className='usuarionovo-button-voltar' onClick={handleVoltar}>Voltar</Button>
    </div>
  )
}

export default ContaColunaNovo