import React from 'react'
import GenericBoxView from '../../components/ViewGeneric/GenericBoxView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons'
import IndicacoesColuna1 from '../../components/Indicacoes/IndicacoesColuna1'
import IndicacoesColuna2 from '../../components/Indicacoes/IndicacoesColuna2'


function Indicacoes() {
  return (
    <GenericBoxView 
    icon={<FontAwesomeIcon icon={faUserGroup} size='2x' />} 
    title={'Indicações'} 
    titleBox={'Gerenciamento das Indicações dos clientes'} 
    coluna1={<IndicacoesColuna1/>} 
    coluna2={<IndicacoesColuna2/>}/>
  )
}

export default Indicacoes