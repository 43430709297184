const criarNovoVendedor = async (data) => {
  const token = sessionStorage.getItem('token');
  fetch(process.env.REACT_APP_URLBK + '/v-1/vendedores/cadastro_vendedor', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${token}`
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(result => {
      if(result['message'] === 'UNIQUE constraint failed: accounts_user.email'){
        alert('Email já Existe') 
      }else{
      alert('Vendedor criado com sucesso.')
      window.location = '/vendedor' 
    }
    })
    .catch(error => {
      console.error('Erro:', error); // Lida com erros de chamada à API
    });
};

export default criarNovoVendedor;

