import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {   faGear, faGifts, faMoneyBill, faPlusCircle, faTable,  faUser, faUserCheck, faUserFriends, faUserGroup, faUserNinja, faUserPlus, faUsers,  } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import {  Link } from 'react-router-dom';
import { faCheckCircle,  faUserCircle } from '@fortawesome/free-regular-svg-icons';
import './SideBar.css'
function SideBar() {
  return (
    <Sidebar style={{marginTop:'50%',border:'none'}}

              >
                <Menu
                  menuItemStyles={{
                    button: ({ level, active, disabled }) => {
                      // only apply styles on first level elements of the tree
                      if (level === 0)
                        return {
                          color: 'white',
                          background: '#30367e',
                          letterSpacing:'2px',
                        };
                    },
                  }}
                >
                  <MenuItem component={<Link to="/" />} icon={<FontAwesomeIcon icon={faTable} />}> Dashboard </MenuItem>
                  <MenuItem component={<Link to="/pontoDigital" target='_blank'/>} icon={<FontAwesomeIcon icon={faCheckCircle} />}> Ponto Digital </MenuItem>
                  <MenuItem component={<Link to="/cliente" target='_blank'/>} icon={<FontAwesomeIcon icon={faUserFriends} />}> Pagina Cliente </MenuItem>
                  <MenuItem component={<Link to="/vendedor/fila" />} icon={<FontAwesomeIcon icon={faUserNinja} />}> Vendedor da Vez </MenuItem>
                  <SubMenu label="Clientes" icon={<FontAwesomeIcon icon={faUser} />}>
                    <MenuItem component={<Link to="/cadastroCliente" />} icon={<FontAwesomeIcon icon={faUserCircle} />}> Cadastro </MenuItem>
                    <MenuItem component={<Link to="/pontuarCliente" />} icon={<FontAwesomeIcon icon={faUserPlus} />}> Pontuar</MenuItem>
                    <MenuItem component={<Link to="/cadastradosClientes" />} icon={<FontAwesomeIcon icon={faUsers} />}> Clientes Cadastrados</MenuItem>
                    <MenuItem component={<Link to="/pontosDistribuidos" />} icon={<FontAwesomeIcon icon={faPlusCircle} />}> Pontos Distribuídos </MenuItem>
                    <MenuItem component={<Link to="/aniversariantesClientes" />} icon={<FontAwesomeIcon icon={faGifts} />}> Aniversariante do Mês </MenuItem>
                    <MenuItem component={<Link to="/statusIndicacoes" />} icon={<FontAwesomeIcon icon={faUserFriends} />}> Status das Indicações </MenuItem>
                  </SubMenu>
                  <SubMenu label="Recursos" icon={<FontAwesomeIcon icon={faPlusCircle} />}>
                    <MenuItem component={<Link to="/recursos/cashback" />} icon={<FontAwesomeIcon icon={faMoneyBill} />}> Cashback </MenuItem>
                    <MenuItem component={<Link to="/recursos/whatsapp" />} icon={<FontAwesomeIcon icon={faWhatsapp} />}> Whatsapp </MenuItem>
                    <MenuItem component={<Link to="/recursos/indicacoes" />} icon={<FontAwesomeIcon icon={faUserGroup} />}> Indicacoes </MenuItem>
                    <MenuItem component={<Link to="/recursos/registro_de_pontos" />} icon={<FontAwesomeIcon icon={faUserCheck} />}>Ponto Dgital</MenuItem>
                  </SubMenu>
                  <SubMenu label="Vendedor" icon={<FontAwesomeIcon icon={faUserNinja} />}>
                    <MenuItem component={<Link to="/vendedor" />} icon={<FontAwesomeIcon icon={faUser} />}> Vendedor </MenuItem>
                  </SubMenu>
                  <SubMenu label="Configuração" icon={<FontAwesomeIcon icon={faGear} />}>
                    <MenuItem component={<Link to="/configuracao/conta" />} icon={<FontAwesomeIcon icon={faUser} />}> Conta </MenuItem>
                    <MenuItem component={<Link to="/configuracao/usuario" />} icon={<FontAwesomeIcon icon={faUserPlus} />}> Usuarios </MenuItem>
                  </SubMenu>
                </Menu>
              </Sidebar>
  )
}

export default SideBar