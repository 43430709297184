import React from 'react'
import { DropdownButton, Dropdown, Col } from 'react-bootstrap'
import Avatar from 'react-avatar';

function logout() {
    localStorage.clear()
    sessionStorage.clear()
  };
  
export default function avatar() {
    var username = sessionStorage.getItem('nome')

    return (
        <Col align="right">
            <DropdownButton className='custom-dropdown '
                key='down'
                id={`dropdown-button-drop-down`}
                drop='down'
                variant="transparent"
                title={<Avatar name={username} round={true} size='50'/>}
                
            >
                <Dropdown.Item href={`/cliente/conta`}>Trocar de senha</Dropdown.Item>
                <Dropdown.Item onClick={logout} href="/cliente">Logout</Dropdown.Item>
            </DropdownButton>
        </Col>
    )
}
