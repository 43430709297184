import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Form } from 'react-bootstrap'
import InputMask from 'react-input-mask';

function ConsultaColuna2({ onFiltrosChange, onSearch }) {
  const handleInputChange = (e) => {
      const { name, value } = e.target;
      onFiltrosChange((prevFiltros) => ({
          ...prevFiltros,
          [name]: value,
      }));
  };

  const handleSearch = () => {
    onSearch();
  };

  return (
      <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
              <Form.Label className='bold'>Nome:</Form.Label>
              <Form.Control
                  type="text"
                  placeholder="Nome do Cliente"
                  name="nome"
                  onChange={handleInputChange}
              />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
              <Form.Label className='bold'>E-mail:</Form.Label>
              <Form.Control
                  type="text"
                  placeholder="E-mail do Cliente"
                  name="email"
                  onChange={handleInputChange}
              />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
              <Form.Label className='bold'>Documento:</Form.Label>
              <InputMask
                            mask="999.999.999-99"
                            maskChar=" "
                            className="form-control"
                            name="documento"
                            placeholder="Digite o CPF do cliente" 
                            onChange={handleInputChange}
                            required
                        />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
              <Form.Label className='bold'>Telefone:</Form.Label>
              <Form.Control
                  type="text"
                  placeholder="Telefone do Cliente"
                  name="telefone"
                  onChange={handleInputChange}
              />
          </Form.Group>
          <Button type="button" onClick={handleSearch}>
              <FontAwesomeIcon icon={faSearch} size='2x' /> Buscar
          </Button>
      </Form>
  );
}
export default ConsultaColuna2