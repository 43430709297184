import { faCheckToSlot, faGift, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import axios from 'axios'
import InputMask from 'react-input-mask';
import CadastroClienteModel from '../modelsGenericos/CadastroCliente'

function PontuarCliente() {
  const initialClienteData = {
    cpf: '',
    valor: '',
  };

  const [cashbackConfig, setCashbackConfig] = useState({
    ativar: false,
    percentual: '',
    centavos: false,
    minimo: '',
    limite: '',
  })

  const [clienteData, setClienteData] = React.useState(initialClienteData);
  const [showCadastro, setShowCadastro] = React.useState(false);
  const [preVisualizacao, setPreVisualizacao] = React.useState();


  const fetchCashback = async () => {
    const token = sessionStorage.getItem('token');
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_URLBK}/v-1/cashback/configuracao`,
        { headers }
      );

      if (response.status === 200) {
        const data = response.data;
        setCashbackConfig({
          ...data,
          ativar: !!data.ativar, // Converte para booleano
          centavos: !!data.centavos, // Converte para booleano
        });

      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    fetchCashback();
  }, []);

  useEffect(() => {
    // Remove caracteres não numéricos do CPF
    const numericCPF = clienteData.cpf.replace(/\D/g, '');

    // Verifica se o CPF tem 11 dígitos
    if (numericCPF.length === 11) {
      

      const fetchClientes = async () => {
        try {
          const token = sessionStorage.getItem('token');
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          };

          const response = await axios.get(`${process.env.REACT_APP_URLBK}/v-1/clientes/consulta?documento=${clienteData.cpf}`,
            { headers }
          );
          const cliente = response.data[0];
          var cashback = cliente.pontos
          if (cashbackConfig.ativar) {
            cashback = cashback * cashbackConfig.percentual / 100
          } else {
            cashback = 0
          }
          setPreVisualizacao(
            <div>
              <h2>Pré-visualização do Cliente</h2>
              <p><strong>Nome:</strong> {cliente.nome}</p>
              <p><strong>Quantidade de Pontos:</strong> {cliente.pontos}</p>
              <p><strong>Cashback:</strong> R$ {cashback.toFixed(2)}</p>
              <p><strong>Telefone:</strong> {cliente.telefone}</p>
              <p><strong>Email:</strong> {cliente.email}</p>
            </div>
          )
        } catch (error) {
          console.error('Erro ao buscar clientes:', error);
        }
      }
   
      fetchClientes();
    }
  }, [clienteData.cpf]); // Monitora mudanças no campo CPF

  var showCadastroModal = () => {
    setShowCadastro(true);
  }

  const handleClose = () => {
    setShowCadastro(false)
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClienteData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    var valorTratado = String(clienteData.valor).replace("R$", "").trim();
    valorTratado = parseFloat(valorTratado.replace(",", "."))

    var dados = {
      "cpf": clienteData.cpf,
      "valor": valorTratado
    }


    try {
      const token = sessionStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_URLBK}/v-1/clientes/pontuar_clientes`,
        dados,
        { headers }
      );
      // Aqui você pode tratar a resposta se necessário
      console.log(response)
      alert('Cliente pontuado com sucesso:', response.data);
      setClienteData(initialClienteData);
    } catch (error) {
      alert('Erro ao pontuar o cliente: ' + error.response.data['error']);
    }
  };

  const handleResgatePontos = async (event) => {
    event.preventDefault();
    var cpfTratado = clienteData.cpf
    var valorTratado = String(clienteData.valor).replace("R$", "").trim();
    valorTratado = parseFloat(valorTratado.replace(",", "."));
    var dados = {
      "cpf": cpfTratado,
      "valor": valorTratado
    }

    try {
      const token = sessionStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_URLBK}/v-1/clientes/resgatar_clientes`,
        dados,
        { headers }
      );
      // Aqui você pode tratar a resposta se necessário
      console.log(response)
      alert('Cliente pontos resgatados com sucesso:', response.data);
      setClienteData(initialClienteData);
    } catch (error) {
      alert('Erro ao resgatar pontos o cliente: ' + error.response.data['error']);
    }
  };

  function mascaraMoeda(event) {
    const onlyDigits = event.target.value
      .split("")
      .filter(s => /\d/.test(s))
      .join("")
      .padStart(3, "0")
    const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2)
    event.target.value = maskCurrency(digitsFloat)
  }

  function maskCurrency(valor, locale = 'pt-BR', currency = 'BRL') {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency
    }).format(valor)
  }
  return (
    <>
      <Form >
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className='bold'>Identifique o cliente</Form.Label>
          <InputMask
            mask="999.999.999-99"
            maskChar=" "
            className="form-control"
            name="cpf"
            placeholder="Digite o CPF do cliente"
            value={clienteData.cpf}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
          <Form.Label className='bold'>Digite o valor da compra</Form.Label>
          <Form.Control
            type="text"
            placeholder="R$ 0,00"
            onInput={(event) => mascaraMoeda(event)}
            name="valor"
            value={clienteData.valor}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <div className='mb-3'>
          <Button className='m-1' onClick={handleSubmit}><FontAwesomeIcon icon={faCheckToSlot} size='2x' /> Pontuar</Button>
          <Button className='m-1' onClick={handleResgatePontos}><FontAwesomeIcon icon={faGift} size='2x' /> Resgatar</Button>
          <Button className='m-1' onClick={showCadastroModal} > <FontAwesomeIcon icon={faUserPlus} size='2x' /> Cadastrar</Button>
        </div>
        {preVisualizacao}
      </Form>
      <Modal show={showCadastro} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>Cadastro Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CadastroClienteModel handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PontuarCliente