import React from 'react'
import GenericBoxViewOneC from '../../components/ViewGeneric/GenericBoxViewOneC'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUser } from '@fortawesome/free-solid-svg-icons'
import ColunaVendedorCadastro from '../../components/Vendedores/VendedorCadastro'

function Vendedor_Cadastro() {
  return (
    <GenericBoxViewOneC 
    icon={<FontAwesomeIcon icon={faUser} size='2x' />} 
    title={'Vendedor'} 
    titleBox={'Gerenciamento de Vendedores'} 
    coluna1={<ColunaVendedorCadastro/>} 
    />
  )
}

export default Vendedor_Cadastro