import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faGear,  faHome, faListCheck, faMailBulk, faSignOut, faUserFriends, } from '@fortawesome/free-solid-svg-icons';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import {  Link } from 'react-router-dom';
import './SideBar.css'
function SideBar() {
  function logout() {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = '/cliente'
  }; 
  return (
    <Sidebar style={{marginTop:'50%',border:'none'}}

              >
                <Menu
                  menuItemStyles={{
                    button: ({ level, active, disabled }) => {
                      // only apply styles on first level elements of the tree
                      if (level === 0)
                        return {
                          color: 'white',
                          background: '#30367e',
                          letterSpacing:'1px',
                          
                        };
                    },
                  }}
                >
                  <MenuItem component={<Link to="/cliente/areadocliente" />} icon={<FontAwesomeIcon icon={faHome} />}> Home </MenuItem>
                  <MenuItem component={<Link to="/cliente/indiqueUmAmigo" />} icon={<FontAwesomeIcon icon={faUserFriends} />}> Indique Um Amigo </MenuItem>
                  <MenuItem component={<Link to="/cliente/pesquisa" />} icon={<FontAwesomeIcon icon={faListCheck} />}> Pesquisa de satisfação </MenuItem>
                  <MenuItem component={<Link to="/cliente/contato" />} icon={<FontAwesomeIcon icon={faMailBulk} />}> Contato </MenuItem>
                  <MenuItem component={<Link to="/cliente/conta" />} icon={<FontAwesomeIcon icon={faGear} />}> Conta </MenuItem>
                  <MenuItem  icon={<FontAwesomeIcon icon={faSignOut} />} onClick={logout}> Logout </MenuItem>

                 
                </Menu>
              </Sidebar>
  )
}

export default SideBar


