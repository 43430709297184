import {  faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function RegistroColuna1() {
  return (
    <>
      <div className='icon-text'>
        <FontAwesomeIcon icon={faUserCheck} size='2x' />
        <h3>Registro dos Pontos Digitais</h3>
      </div>
      <p>Utilize os campos para buscar os Funcionais e Mês.</p>
    </>
  )
}

export default RegistroColuna1