import React from 'react'
import GenericBoxView from '../../components/ViewGeneric/GenericBoxView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import CashbackColuna1 from '../../components/Cashback/CashbackColuna1'
import CashbackColuna2 from '../../components/Cashback/CashbackColuna2'


function Cashback() {
  return (
    <GenericBoxView 
    icon={<FontAwesomeIcon icon={faMoneyBill} size='2x' />} 
    title={'Cashback'} 
    titleBox={'Gerenciamento do Cashback dos clientes'} 
    coluna1={<CashbackColuna1/>} 
    coluna2={<CashbackColuna2/>}/>
  )
}

export default Cashback