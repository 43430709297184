import React from 'react'
import { Card,  Tab, Tabs } from 'react-bootstrap'

function GenericTabBoxViewOneC(props) {
  return (
    <Tabs
      defaultActiveKey={props.title}
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey={props.title} title={props.title}>
        <Card.Body>
          {props.coluna1}
        </Card.Body>
      </Tab>
    </Tabs>
  )
}

export default GenericTabBoxViewOneC