import React, {useState} from 'react'
import './PontoDigital.css'
import { Card, Form, Button, FloatingLabel, Image } from 'react-bootstrap'
import logo from '../../assets/logo.png'
import axios from 'axios'

function PontoDigital() {
  const [usuario, setUsuario] = useState(''); // Estado para o nome de usuário
  const [senha, setSenha] = useState(''); // Estado para a senha

  const handleRegistrar = async (e) => {
    e.preventDefault();

    try {
      
      const response = await axios.post(
        `${process.env.REACT_APP_URLBK}/v-1/ponto/registro`, // Substitua com a URL correta da sua API
        {
          login: usuario,
          senha: senha,
        },
        {
          'Content-Type': 'application/json'
        }
      );

      // Se o ponto for registrado com sucesso, você pode tratar a resposta aqui
      alert(response.data['message']);
    } catch (error) {
      // Se houver um erro, você pode tratar o erro aqui
      console.error('Erro ao registrar ponto:', error);
    }
  };
  return (
    <>
      <div className='background-ponto-digital'>
        <div className='div-card-ponto-digital'>
          <Card className="p-5">
            <Card.Title>
            <div className='logo-ponto-digital'>
                <Image src={logo} />
            </div>
              <h1>Ponto Digital</h1>
            </Card.Title>
            <Card.Body>
            <Form onSubmit={handleRegistrar}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Usuário"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Usuário"
                    value={usuario}
                    onChange={(e) => setUsuario(e.target.value)}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingPassword"
                  label="Senha"
                  className="mb-3"
                >
                  <Form.Control
                    type="password"
                    placeholder="Senha"
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                  />
                </FloatingLabel>
                <Button type="submit" className='btn button-prime-orange-login'>Registrar</Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}

export default PontoDigital