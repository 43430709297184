import React, { useState } from 'react';
import { Container, Form, Button, Row, Col, Image } from 'react-bootstrap';
import SatisfacaoPesqueisaFoto from '../../assets/satisfacao.png'

function PesquisaDeSatisfacao() {
  const [resposta1, setResposta1] = useState('');
  const [resposta2, setResposta2] = useState('');
  const [resposta3, setResposta3] = useState('');
  const [comentarios, setComentarios] = useState('');
  const [recomendacao, setRecomendacao] = useState('');

  const handleEnviarRespostas = () => {
    // Aqui você pode enviar as respostas para o servidor ou realizar qualquer ação desejada
    console.log('Resposta 1:', resposta1);
    console.log('Resposta 2:', resposta2);
    console.log('Resposta 3:', resposta3);
    console.log('Comentários:', comentarios);
    console.log('Recomendação:', recomendacao);
  };

  return (
    <Container>
          <div className='container-pc-cliente'>
          <div className='indiqueumAmigo'>

      <h1 className="mt-5">Pesquisa de Satisfação</h1>
      <Form>
        <Row className="mt-4">
          <Col>
            <Form.Group controlId="resposta1">
              <Form.Label>
                Qual a probabilidade de você nos indicar para um amigo ou parente? <br></br>(de 1 a 10)
              </Form.Label>
              <Form.Control
                type="number"
                min="1"
                max="10"
                value={resposta1}
                onChange={(e) => setResposta1(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="resposta2">
              <Form.Label>
                Avalie nosso atendimento. Dê uma nota: (sendo 1 insatisfeito e 10 extremamente satisfeito)
              </Form.Label>
              <Form.Control
                type="number"
                min="1"
                max="10"
                value={resposta2}
                onChange={(e) => setResposta2(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Form.Group controlId="resposta3">
              <Form.Label>
                O produto ou serviço atendeu suas expectativas? (de 1 a 10)
              </Form.Label>
              <Form.Control
                type="number"
                min="1"
                max="10"
                value={resposta3}
                onChange={(e) => setResposta3(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Form.Group controlId="comentarios">
              <Form.Label>O que você acha que podemos melhorar?</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={comentarios}
                onChange={(e) => setComentarios(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Form.Group controlId="recomendacao">
              <Form.Label>
                Qual a probabilidade de você recomendar-nos para um amigo ou familiar?
              </Form.Label>
              <Form.Control
                type="number"
                min="1"
                max="10"
                value={recomendacao}
                onChange={(e) => setRecomendacao(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Form.Group controlId="enviarComentarios">
              <Form.Label>Deixe um comentário sobre a sua experiência conosco.</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={comentarios}
                onChange={(e) => setComentarios(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" className="mt-4 button-prime-orange-login " onClick={handleEnviarRespostas} style={{'width':'100%'}}>
          Enviar
        </Button>
      </Form>
      <Image src={SatisfacaoPesqueisaFoto} className='satisfacao-img'/>

      </div>
      </div>
    </Container>
  );
}

export default PesquisaDeSatisfacao;