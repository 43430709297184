import React from 'react'
import GenericBoxViewOneC from '../../components/ViewGeneric/GenericBoxViewOneC'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUserPlus } from '@fortawesome/free-solid-svg-icons'
import UsuariosColunaNovo from '../../components/Usuarios/UsuariosColunaNovo'

function Usuarios() {
  return (
    <GenericBoxViewOneC 
    icon={<FontAwesomeIcon icon={faUserPlus} size='2x' />} 
    title={'Usuarios'} 
    titleBox={'Gerenciamento da Conta'} 
    coluna1={<UsuariosColunaNovo/>} 
    />
  )
}

export default Usuarios