import { Col, Container, Row, Image } from 'react-bootstrap';
import Dashboard from './views/Dashboard/Dashboard'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css'

import Logo from './assets/logo.png'

import CadastroCliente from './views/Cliente/CadastroCliente';
import SideBar from './components/SideBar/SideBar';
import SideBarCliente from './components/SideBar/SideBarCliente';
import PontuarCliente from './views/Cliente/PontuarCliente';
import ClientesCadastrados from './views/Cliente/ClientesCadastrados';
import PontosDistribuidos from './views/Cliente/PontosDistribuidos';
import StatusIndicacoes from './views/Cliente/StatusIndicacoes';
import Aniversariantes from './views/Cliente/Aniversariantes';
import Cashback from './views/Recursos/Cashback';
import Whatsapp from './views/Recursos/Whatsapp';
import Conta from './views/Configuracao/Conta';
import Caixa from './views/Caixa/Caixa';
import Login from './views/Login/Login';
import Usuarios from './views/Configuracao/Usuarios';
import UsuariosNovo from './views/Configuracao/Usuarios_Novo';
import UsuariosEditar from './views/Configuracao/Usuarios_Editar';
import Avatar from './components/Avatar/Avatar'
import AvatarCliente from './components/Avatar/AvatarCliente'
import Indicacoes from './views/Recursos/Indicacoes';
import Vendedor from './views/Vendedor/Vendedor'
import Fila from './views/Vendedor/Acompanhamento_Fila'
import VendedorCadastro from './views/Vendedor/Cadastro'
import VendedorEditar from './views/Vendedor/Editar'
import PontoDigital from './views/PontoDigital/PontoDigital'
import RegistroPonto from './views/Recursos/RegistroPonto'
import PCLogin from './views/PCliente/Login'
import PCAreaDoCliente from './views/PCliente/AreaDoCliente'
import PCIndiqueUmAmigo from './views/PCliente/IndiqueUmAmigo'
import PCPesquisa from './views/PCliente/PesquisaDeSatisfacao'
import PCContato from './views/PCliente/Contato'
import PCConta from './views/PCliente/Conta'

function getToken() {
  const tokenString = sessionStorage.getItem('token');
  return tokenString !== null

}

function getRole() {
  const userRoleString = sessionStorage.getItem('role');
  return userRoleString 

}

function App() {

  const token = getToken();
  const userRole = getRole();

  const PrivateRoute = ({ children }) => {
    return token && userRole === "1" ? children : <Navigate to="/login" />;
  };

  const PrivateRouteForAll = ({ children }) => {
    return token ? children : <Navigate to="/login" />;
  };

  const PrivateRouteForCliente = ({ children }) => {
    return token ? children : <Navigate to="/cliente" />;
  };



  return (
    <Router>
      <Container fluid>
        <Routes>
          {/* lojistas */}
          <Route exact path="/vendedor" element={WithSidebarRoutes(<PrivateRoute><Vendedor /></PrivateRoute>)} />
          <Route exact path="/vendedor/fila" element={WithSidebarRoutes(<PrivateRoute><Fila /></PrivateRoute>)} />
          <Route exact path="/vendedor/cadastro" element={WithSidebarRoutes(<PrivateRoute><VendedorCadastro/></PrivateRoute>)} />
          <Route exact path="/vendedor/editar/:userId" element={WithSidebarRoutes(<PrivateRoute><VendedorEditar/></PrivateRoute>)} />
          <Route exact path="/caixa" element={CaixaWithoutSidebar(<PrivateRouteForAll><Caixa /></PrivateRouteForAll>)} />
          <Route exact path="/configuracao/conta" element={WithSidebarRoutes(<PrivateRoute><Conta /></PrivateRoute>)} />
          <Route exact path="/configuracao/usuario" element={WithSidebarRoutes(<PrivateRoute><Usuarios /></PrivateRoute>)} />
          <Route exact path="/configuracao/usuario/editar/:userId" element={WithSidebarRoutes(<PrivateRoute><UsuariosEditar /></PrivateRoute>)} />
          <Route exact path="/configuracao/usuario/cadastrar" element={WithSidebarRoutes(<PrivateRoute><UsuariosNovo /></PrivateRoute>)} />
          <Route exact path="/recursos/registro_de_pontos" element={WithSidebarRoutes(<PrivateRoute><RegistroPonto /></PrivateRoute>)} />
          <Route exact path="/recursos/cashback" element={WithSidebarRoutes(<PrivateRoute><Cashback /></PrivateRoute>)} />
          <Route exact path="/recursos/whatsapp" element={WithSidebarRoutes(<PrivateRoute><Whatsapp /></PrivateRoute>)} />
          <Route exact path="/recursos/indicacoes" element={WithSidebarRoutes(<PrivateRoute><Indicacoes /></PrivateRoute>)} />
          <Route exact path="/statusIndicacoes" element={WithSidebarRoutes(<PrivateRoute><StatusIndicacoes /></PrivateRoute>)} />
          <Route exact path="/aniversariantesClientes" element={WithSidebarRoutes(<PrivateRoute><Aniversariantes /></PrivateRoute>)} />
          <Route exact path="/pontosDistribuidos" element={WithSidebarRoutes(<PrivateRoute><PontosDistribuidos /></PrivateRoute>)} />
          <Route exact path="/cadastradosClientes" element={WithSidebarRoutes(<PrivateRoute><ClientesCadastrados /></PrivateRoute>)} />
          <Route exact path="/pontuarCliente" element={WithSidebarRoutes(<PrivateRoute><PontuarCliente /></PrivateRoute>)} />
          <Route exact path="/cadastroCliente" element={WithSidebarRoutes(<PrivateRoute><CadastroCliente /></PrivateRoute>)} />
          <Route exact path="/" element={WithSidebarRoutes(<PrivateRoute><Dashboard /></PrivateRoute>)} />
          <Route exact path="/pontoDigital" element={CaixaWithoutSidebar(<PontoDigital/>)} />
          <Route exact path="/login" element={CaixaWithoutSidebar(<Login />)} />
          {/*Cliente*/}
          <Route exact path="/cliente/contato" element={WithSidebarRoutesCliente(<PrivateRouteForCliente><PCContato/></PrivateRouteForCliente>)} />
          <Route exact path="/cliente/pesquisa" element={WithSidebarRoutesCliente(<PrivateRouteForCliente><PCPesquisa/></PrivateRouteForCliente>)} />
          <Route exact path="/cliente/indiqueUmAmigo" element={WithSidebarRoutesCliente(<PrivateRouteForCliente><PCIndiqueUmAmigo/></PrivateRouteForCliente>)} />
          <Route exact path="/cliente/conta" element={WithSidebarRoutesCliente(<PrivateRouteForCliente><PCConta/></PrivateRouteForCliente>)} />
          <Route exact path="/cliente/areadocliente" element={WithSidebarRoutesCliente(<PrivateRouteForCliente><PCAreaDoCliente/></PrivateRouteForCliente>)} />
          <Route exact path="/cliente" element={CaixaWithoutSidebar(<PCLogin/>)} />
        </Routes>
      </Container>
    </Router>
  ) 
}

function CaixaWithoutSidebar(element) {
  return (
    <>
      {element}
    </>
  );
}

function WithSidebarRoutes(element) {
  return (
    <>
      <Row className='coluna-h-navbar'>
        <Col xs={9} sm={9}>
          <Image className='logo-navbar-clienteprime' src={Logo}/>
        </Col>
        <Col xs={3} sm={3}>
          <Avatar/>
        </Col>
      </Row>
      <Row>
        <Col xs={2} className='coluna-navbar'>
          <div>
            <SideBar />
          </div>
        </Col>
        <Col xs={9} className='coluna-dados'>
          {element}
        </Col>
        <Col xs={1} className='coluna-navbar-end'></Col>
      </Row>
    </>
  );
}

function WithSidebarRoutesCliente(element) {
  return (
    <>
      <Row className='coluna-h-navbar'>
      <Col xs={9} sm={9}>
      <Image className='logo-navbar-clienteprime' src={Logo}/>
        </Col>
        <Col xs={3} sm={3} >
          <AvatarCliente />
        </Col>
      </Row>
      <Row>
        <Col xs={2} className='coluna-navbar'>
          <div>
            <SideBarCliente />
          </div>
        </Col>
        <Col xs={9} className='coluna-dados'>
          {element}
        </Col>
        <Col xs={1} className='coluna-navbar-end'></Col>
      </Row>
    </>
  );
}

export default App;