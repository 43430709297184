import React from 'react'
import GenericBoxViewOneC from '../../components/ViewGeneric/GenericBoxViewOneC'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUserNinja } from '@fortawesome/free-solid-svg-icons'
import VendedorDaVez from '../../components/Vendedores/VendedorDaVez'

function Acompanhamento_Fila() {
  return (
    <GenericBoxViewOneC 
    icon={<FontAwesomeIcon icon={faUserNinja} size='2x' />} 
    title={'Fila Vendedor'} 
    titleBox={'Fila vendedores da vez'} 
    coluna1={<VendedorDaVez/>} 
    />
  )
}

export default Acompanhamento_Fila