import { faPencil, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Form, Table } from 'react-bootstrap'
import consultaUsuarioEmpresa from '../../services/usuario/usuarios_empresa'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';

function ContaColuna1() {
  const [usuarios, setUsuarios] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = process.env.REACT_APP_ITEM_FOR_PAGE;
  
  const filteredUsuarios = usuarios.filter(
    (usuario) =>
      usuario.login.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usuariosData = await consultaUsuarioEmpresa();
        setUsuarios(usuariosData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleExcluir = (user_id) => {
    const token = sessionStorage.getItem('token');
    const confirmacao = window.confirm('Tem certeza de que deseja excluir o usuário?');
    if (confirmacao) {
      const headers = {
        "Content-Type": "application/json",
        "Authorization": "Token " + token
      };

      axios
        .post(process.env.REACT_APP_URLBK + '/v-1/accounts/excluir_usuario', { user_id }, { headers })
        .then((response) => {
          alert(response.data.message);
          // Realize qualquer ação adicional após a exclusão
          window.location = '/configuracao/usuario'
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };
  const totalPages = Math.ceil(filteredUsuarios.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsuarios.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Resetar a página para a primeira ao fazer uma nova busca
  };

 
    return (
      <>
        <div className='configuracao-usuario-button-novo'>
          <Link to="/configuracao/usuario/cadastrar" ><Button className='mb-3 bg-green'>Novo Usuário</Button></Link>
        </div>
        <Form.Group className="mb-3" controlId="search">
        <Form.Control
          type="text"
          placeholder="Buscar por login"
          value={searchTerm}
          onChange={handleSearch}
        />
      </Form.Group>
       <Table striped bordered hover>
        <thead>
          <tr>
            <th>Login</th>
            <th>Email</th>
            <th>Empresa</th>
            <th>Ativo</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((usuario, index) => (
            <tr key={index}>
              <td>{usuario.login}</td>
              <td>{usuario.email}</td>
              <td>{usuario.empresa__nome}</td>
              <td>{usuario.is_active ? 'Sim' : 'Não'}</td>
              <td>
                <Link to={'/configuracao/usuario/editar/' + usuario.id}><Button>editar <FontAwesomeIcon icon={faPencil} /></Button></Link>{' '}
                <Button variant='danger' onClick={() => handleExcluir(usuario.id)} >excluir <FontAwesomeIcon icon={faX} /></Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

         {/* Paginação */}
      <div className="d-flex justify-content-center">
        <ul className="pagination">
          <li
            className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
          >
            <Button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Anterior
            </Button>
          </li>
          {Array.from({ length: totalPages }).map((_, index) => (
            <li
              key={index}
              className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}
            >
              <Button
                className="page-link"
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Button>
            </li>
          ))}
          <li
            className={`page-item ${currentPage === totalPages ? 'disabled' : ''
              }`}
          >
            <Button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Próxima
            </Button>
            </li>
          </ul>
        </div>

      </>
    )
  }

  export default ContaColuna1