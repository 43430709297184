import axios from 'axios'

const compraAddResgatar = async(clienteData) => {
    try{
        var dados = {
            "cpf": clienteData.cpf,
            "valor": clienteData.valor
        }

            const token = sessionStorage.getItem('token');
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            };

            const response = await axios.post(
                `${process.env.REACT_APP_URLBK}/v-1/clientes/comprar_clientes`,
                dados,
                { headers }
            );
            return response
        }catch(error){
            return error
        }
}

export default compraAddResgatar


    
