import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckToSlot } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Table } from 'react-bootstrap'
import axios from 'axios'
import { useState } from 'react'
import { format } from 'date-fns';

function PontosDistribuidos() {
    const [clientes, setClientes] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = process.env.REACT_APP_ITEM_FOR_PAGE;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                };

                const response = await axios.get(`${process.env.REACT_APP_URLBK}/v-1/clientes/consulta/pontos/`, { headers });
                setClientes(response.data);
            } catch (error) {
                console.error('Erro ao buscar contate o administrador:', error);
            }
        };

        fetchData(); // Chama a função assíncrona imediatamente
    }, []);

    
    const totalPages = Math.ceil(clientes.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = clientes.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
      };
     

    return (
        <>
            <div className='icon-text'>
                <FontAwesomeIcon icon={faCheckToSlot} size='2x' /><h3> Pontos Distribuidos</h3>
            </div>
            <hr></hr>
            <Card>
                <Card.Title className='cadastroNovoscliente-card-title'><h4>Pontos Distribuidos</h4></Card.Title>
                <Card.Body>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Cliente</th>
                                <th>Qtd Pontos</th>
                                <th>Cashback</th>
                                <th>Email</th>
                                <th>Telefone</th>
                                <th>Cadastro</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((cliente) => (
                                <tr key={cliente.id}>
                                    <td>{cliente.nome}</td>
                                    <td>{cliente.pontos}</td>
                                    <td>{cliente.cashback}</td>
                                    <td>{cliente.email}</td>
                                    <td>{cliente.telefone}</td>
                                    <td>{format(new Date(cliente.dataCadastro), 'dd/MM/yyyy')}</td>
                                    <td>
                                        {/* Coloque aqui os botões de ação para cada cliente */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                     {/* Paginação */}
      <div className="d-flex justify-content-center">
        <ul className="pagination">
          <li
            className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
          >
            <Button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Anterior
            </Button>
          </li>
          {Array.from({ length: totalPages }).map((_, index) => (
            <li
              key={index}
              className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}
            >
              <Button
                className="page-link"
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Button>
            </li>
          ))}
          <li
            className={`page-item ${currentPage === totalPages ? 'disabled' : ''
              }`}
          >
            <Button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Próxima
            </Button>
            </li>
          </ul>
        </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default PontosDistribuidos