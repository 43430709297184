import React, { useState, useEffect } from 'react';
import GenericBoxView from '../../components/ViewGeneric/GenericBoxView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGifts } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, Table } from 'react-bootstrap';
import AniversariantesColuna1 from '../../components/Aniversariantes/AniversariantesColuna1';
import axios from 'axios';

function calculateAge(birthday) {
  const birthDate = new Date(birthday);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  
  return age;
}

function Aniversariantes() {
  const [aniversariantes, setAniversariantes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = process.env.REACT_APP_ITEM_FOR_PAGE;

  useEffect(() => {
    fetchAniversariantes();
  }, []);

  const fetchAniversariantes = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_URLBK}/v-1/clientes/consulta/aniversariante/`,
        { headers }
      );
      
      const aniversariantesWithAge = response.data.map((aniversariante) => ({
        ...aniversariante,
        idade: calculateAge(aniversariante.dataNascimento),
      }));

      setAniversariantes(aniversariantesWithAge);
    } catch (error) {
      console.error('Erro ao buscar aniversariantes:', error);
    }
  };
  const totalPages = Math.ceil(aniversariantes.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = aniversariantes.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

  return (
    <>
      <GenericBoxView
        icon={<FontAwesomeIcon icon={faGifts} size='2x' />}
        title={'Aniversariantes'}
        titleBox={'Aniversariantes'}
        coluna1={<AniversariantesColuna1 />}
      />
      <hr></hr>
      <Card>
        <Card.Title className='cadastroNovoscliente-card-title'><h4> Resultados</h4></Card.Title>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Cliente</th>
                <th>E-mail</th>
                <th>Data de Nascimento</th>
                <th>Idade</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((aniversariante) => (
                <tr key={aniversariante.cliente_id}>
                  <td>{aniversariante.nome}</td>
                  <td>{aniversariante.email}</td>
                  <td>{aniversariante.dataNascimento}</td>
                  <td>{aniversariante.idade} Anos</td>
                  <td>
                    {/* Coloque aqui os botões de ação para cada aniversariante */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
           {/* Paginação */}
      <div className="d-flex justify-content-center">
        <ul className="pagination">
          <li
            className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
          >
            <Button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Anterior
            </Button>
          </li>
          {Array.from({ length: totalPages }).map((_, index) => (
            <li
              key={index}
              className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}
            >
              <Button
                className="page-link"
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Button>
            </li>
          ))}
          <li
            className={`page-item ${currentPage === totalPages ? 'disabled' : ''
              }`}
          >
            <Button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Próxima
            </Button>
            </li>
          </ul>
        </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default Aniversariantes;