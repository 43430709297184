import axios from 'axios'

const fetchCashback = async () => {
    const token = sessionStorage.getItem('token');
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_URLBK}/v-1/cashback/configuracao`,
        { headers }
      );

      if (response.status === 200) {
        const data = response.data;
        return ({
          ...data,
          ativar: !!data.ativar, // Converte para booleano
          centavos: !!data.centavos, // Converte para booleano
        });

      }
    } catch (error) {
      alert(error);
    }
  };

export default fetchCashback