import React from 'react'
import GenericBoxView from '../../components/ViewGeneric/GenericBoxView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckToSlot } from '@fortawesome/free-solid-svg-icons'
import PontuarColuna1 from '../../components/Pontuar/PontuarColuna1'
import PontuarColuna2 from '../../components/Pontuar/PontuarColuna2'


function PontuarCliente() {
  return (
    <GenericBoxView 
    icon={<FontAwesomeIcon icon={faCheckToSlot} size='2x' />} 
    title={'Pontuar Cliente'} 
    titleBox={'Pontuar Cliente'} 
    coluna1={<PontuarColuna1/>} 
    coluna2={<PontuarColuna2/>}/>
  )
}

export default PontuarCliente