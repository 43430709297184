import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap'
import consultaUsuarioEmpresa from '../../services/usuario/usuarios_empresa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

function RegistroColuna2({ onFiltrosChange, onSearch }) {
  const [usuarios, setUsuarios] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usuariosData = await consultaUsuarioEmpresa();
        setUsuarios(usuariosData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onFiltrosChange((prevFiltros) => ({
      ...prevFiltros,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = format(date, 'MM-yyyy');
    onFiltrosChange((prevFiltros) => ({
      ...prevFiltros,
      mesano: formattedDate, // Use a data selecionada diretamente ou formate conforme necessário
    }));
  };

  const handleSearch = () => {
    onSearch();
  };

  return (
    <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
        <Form.Label className='bold'>Funcionário:</Form.Label>
        <Form.Select
          onChange={handleInputChange}
          name='funcionario'
        >
          <option value="">Selecione um funcionário</option>
          {usuarios.map((usuario, index) => (
            <option key={index} value={usuario.id}>
              {usuario.nome}
            </option>
          ))}
        </Form.Select>

      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
        <Form.Label className='bold'>Mês e Ano:</Form.Label>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          className='form-control'
          name='mesano'
        />
      </Form.Group >
      <Button type="button" onClick={handleSearch}>
        <FontAwesomeIcon icon={faSearch} size='2x' /> Buscar
      </Button>
    </Form>
  );
}
export default RegistroColuna2