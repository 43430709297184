import React from 'react'
import { Card, Col, Row, Tab, Tabs } from 'react-bootstrap'

function GenericTabBoxView(props) {
  return (
    <Tabs
      defaultActiveKey={props.title}
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey={props.title} title={props.title}>
        <Card.Body>
          <Row>
            <Col>
              {props.coluna1}
            </Col>
            <Col>
              {props.coluna2}
            </Col>
          </Row>
        </Card.Body>
      </Tab>
    </Tabs>
  )
}

export default GenericTabBoxView