import {  faSave} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import axios from 'axios'


function PontuarColuna2() {

  const [cashback, setCashback] = useState({
    ativar: false,
    percentual: '',
    centavos: false,
    minimo: '',
    limite: '',
  })

  

  useEffect(() => {
    const fetchData = async () => {
      const token = sessionStorage.getItem('token');
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        };
        const response = await axios.get(
          `${process.env.REACT_APP_URLBK}/v-1/cashback/configuracao`,
          { headers }
        );

        if (response.status === 200) {
          const data = response.data;
          console.log(data)
          setCashback({
            ...data,
            ativar: !!data.ativar, // Converte para booleano
            centavos: !!data.centavos, // Converte para booleano
          });
        }
      } catch (error) {
        alert(error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === 'checkbox' ? checked : value;
    setCashback((prevCashback) => ({
      ...prevCashback,
      [name]: inputValue,
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem('token');
    try {
      const data = {
        ...cashback
      }
        
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_URLBK}/v-1/cashback/configuracao`,
        data,
        { headers }
      );
      if (response.status === 200) {
        alert(response.data.message); // Mensagem de sucesso vinda do Backend
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5" >
        <Form.Label className='bold'>Ativar</Form.Label>
        <Form.Check type="checkbox" name='ativar' onChange={handleInputChange}  checked={cashback.ativar}/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
        <Form.Label className='bold'>Percentual de Cashback</Form.Label>
        <Form.Control type="number" placeholder="0%" name='percentual' onChange={handleInputChange} value={cashback['percentual']}/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
        <Form.Label className='bold'>Permitir Centavos. ex: R$ 6,55</Form.Label>
        <Form.Check type="checkbox" name='centavos' onChange={handleInputChange}  checked={cashback.centavos}/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
        <Form.Label className='bold'>Valor Minimo para Regate</Form.Label>
        <Form.Control type="number" placeholder="R$ 0" name='minimo' onChange={handleInputChange} value={cashback['minimo']}/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
        <Form.Label className='bold'>Limitar o valor do Resgate</Form.Label>
        <Form.Control type="number" placeholder="R$ 0" name='limite' onChange={handleInputChange} value={cashback['limite']}/>
      </Form.Group>
      <div className='mb-3'>
        <Button className='m-1 usuarionovo-button-criar' type='submit'><FontAwesomeIcon icon={faSave} size='2x' /> Salvar</Button>
      </div>
    </Form>

  )
}

export default PontuarColuna2