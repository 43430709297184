import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Button, Card } from 'react-bootstrap';

const fetchClientes = async (cpf, cashbackConfig, setPontosAtuaisCliente, setPreVisualizacao, showCadastroModal) => {
  try {
    const token = sessionStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token}`,
    };

    const response = await axios.get(`${process.env.REACT_APP_URLBK}/v-1/clientes/consulta?documento=${cpf}`,
      { headers }
    );
    if (response.data && response.data.length > 0) {
      const cliente = response.data[0];
      var cashback = cliente.pontos;

      if (cashbackConfig.ativar) {
        cashback = cashback * cashbackConfig.percentual / 100;
      } else {
        cashback = 0;
      }

      setPontosAtuaisCliente({
        pontos: cliente.pontos,
        cashback: cashback
      })
      setPreVisualizacao(
        <div >
          <h2>Pré-visualização do Cliente</h2>
          <p><strong>Nome:</strong> {cliente.nome}</p>
          <p><strong>Quantidade de Pontos:</strong> {(Math.trunc(cliente.pontos * 100) / 100).toFixed(2)}</p>
          <p><strong>Cashback:</strong> R$ {(Math.trunc(cashback * 100) / 100).toFixed(2)}</p>
          <p><strong>Telefone:</strong> {cliente.telefone}</p>
          <p><strong>Email:</strong> {cliente.email}</p>
        </div>
      );
    } else {
      // Caso não haja dados de cliente na resposta
      console.error('Nenhum cliente encontrado.');
      setPreVisualizacao(<div align="center"> 
        <h2>Cliente não cadastrado</h2>
         <div className='caixa-container-buttons'>
        <Card onClick={showCadastroModal}>
          <Card.Title className='bg-blue clicavel'><FontAwesomeIcon icon={faUserPlus} size='2x' color='white' /></Card.Title>
          <Button >Cadastrar</Button>
        </Card>
      </div></div>)
    }
  } catch (error) {
    console.error('Erro ao buscar clientes:', error);
  }
};

export default fetchClientes;