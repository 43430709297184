import React from 'react'
import { Button, Card,  Form} from 'react-bootstrap'


function VoucherCliente() {
  const initialClienteData = {
    voucher: '',
    cliente: '',
  };

  const [clienteData, setClienteData] = React.useState(initialClienteData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClienteData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      alert('Voucher resgatado com sucesso:');
      setClienteData(initialClienteData);
    } catch (error) {
      alert('Erro ao cadastrar o cliente: ' + error.response.data['error']);
    }
  };

  return (
    <>
    
      <Card>
        <Card.Body>

              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label className='bold'>Voucher</Form.Label>
                  <Form.Control
                    type="text"
                    name="voucher"
                    placeholder="ASDASD-ZXCCZ-ASDSAD"
                    value={clienteData.voucher}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Cadastrar Voucher
                </Button>
              </Form>
          
        </Card.Body>
      </Card>
    </>
  )
}

export default VoucherCliente