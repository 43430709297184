import axios from 'axios';

const consultaVendedoresEmpresa = async () => {
  try {
    const token = sessionStorage.getItem('token');
    const response = await axios.get(process.env.REACT_APP_URLBK + '/v-1/vendedores/consulta_vendedor', {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + token
      },
      credentials: "include",
    });
    return response.data['usuarios'];
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export default consultaVendedoresEmpresa;
