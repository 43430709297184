import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';

function FormularioContato() {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [mensagem, setMensagem] = useState('');

  const handleEnviarMensagem = () => {
    // Aqui você pode enviar a mensagem para o servidor ou realizar qualquer ação desejada
    console.log('Nome:', nome);
    console.log('Email:', email);
    console.log('Telefone:', telefone);
    console.log('Mensagem:', mensagem);
  };

  return (
    <Container>
      <div className='container-pc-cliente'>
          <div className='indiqueumAmigo'>
      <h1 className="mt-5">Contato</h1>
      <h5>Queremos ouvir sua opinião! Se você tem dúvidas, sugestões ou reclamações sobre nossa empresa ou nosso programa de fidelização, por favor, compartilhe conosco preenchendo o formulário abaixo. Estamos ansiosos para entrar em contato com você.</h5>
      <Form>
        <Row className="mt-4">
          <Col>
            <Form.Group controlId="nome">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Form.Group controlId="telefone">
              <Form.Label>Telefone</Form.Label>
              <Form.Control
                type="tel"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Form.Group controlId="mensagem">
              <Form.Label>Mensagem</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={mensagem}
                onChange={(e) => setMensagem(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" className="mt-4 button-prime-orange-login " onClick={handleEnviarMensagem} style={{'width':'100%'}}>
          Enviar
        </Button>
      </Form>
      </div>
      </div>
    </Container>
  );
}

export default FormularioContato;