import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Container, Row, Col, Image } from 'react-bootstrap'
import IndiqueUmAmigoFoto from '../../assets/indiqueumamigo.png'

function IndiqueUmAmigo() {
  const handleSubmit = () => {
    const url = `https://api.whatsapp.com/send?text=${sessionStorage.getItem('nome')} acabou de te convidar para o Cliente Prime - acesse https://clienteprime.com.br/cliente/`
    window.open(url, '_blank')
  }
  return (
    <Container>
      <div className='container-pc-cliente'>
        <div className='indiqueumAmigo'>
        <h2 className='mt-5 mb-5'>Indique Amigos e Ganhe Pontos e Cashback</h2>

          <Row>
            <Col className='mt-5 '>

            <div className='card-indiqueumamigo mt-3'>

          <p >Você sabia que pode aproveitar vantagens incríveis ao indicar amigos para nosso programa? A cada amigo indicado, você não apenas fortalece nossa comunidade, mas também acumula benefícios exclusivos.</p>

          <p>Por cada amigo que você recomendar, você ganha <strong>pontos</strong>! E não é só isso, cada ponto equivale a <strong>a uma porcentagem de cashback</strong>. Portanto, quanto mais amigos você indicar, mais pontos e cashback você acumulará.</p>

          <p>Ganhar é simples. Basta preencher os dados abaixo e informar-nos quem você gostaria de convidar. Junte-se a nós e comece a desfrutar dos benefícios hoje mesmo!</p>

          </div>
          <Button variant='success' onClick={handleSubmit} className='mt-5 mb-5 w-100'><FontAwesomeIcon icon={faWhatsapp} size='2x'/> Indique e Ganhe agora mesmo!</Button>

          </Col>
          <Col>
          <Image src={IndiqueUmAmigoFoto} className='indiqueUmAmigo-img'/>
          </Col>
          </Row>
          
          </div>
          </div>
      </Container>
  )
}

export default IndiqueUmAmigo