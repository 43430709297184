import React, { useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
function VendedorDaVez() {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );
    const [vendedores, setVendedores] = useState([
        { id: 1, nome: 'João', vendas: 0, espera: 0, nvendeu: 0, motivosNaoVenda: { "Preço alto": 0, "Indisponibilidade de produto": 0, "Outros": 0 }, motivoSelecionado: '' },
        { id: 2, nome: 'Maria', vendas: 0, espera: 0, nvendeu: 0, motivosNaoVenda: { "Preço alto": 0, "Indisponibilidade de produto": 0, "Outros": 0 }, motivoSelecionado: '' },
        { id: 3, nome: 'Pedro', vendas: 0, espera: 0, nvendeu: 0, motivosNaoVenda: { "Preço alto": 0, "Indisponibilidade de produto": 0, "Outros": 0 }, motivoSelecionado: '' },
        { id: 4, nome: 'Ana', vendas: 0, espera: 0, nvendeu: 0, motivosNaoVenda: { "Preço alto": 0, "Indisponibilidade de produto": 0, "Outros": 0 }, motivoSelecionado: '' },
    ]);
    const [vendedorDaVez, setVendedorDaVez] = useState(0);
    const [vendedorSelecionado, setVendedorSelecionado] = useState(null);
    
    const handleMotivoSelecionado = (vendedorId, motivo) => {
        const updatedVendedores = [...vendedores];
        const index = vendedores.findIndex(vendedor => vendedor.id === vendedorId);
        if (index !== -1) {
            updatedVendedores[index].motivoSelecionado = motivo;
            setVendedores(updatedVendedores);
        }
    };

    const handleRegistarNVenda = (vendedorId) => {
        const updatedVendedores = [...vendedores];
        const index = vendedores.findIndex(vendedor => vendedor.id === vendedorId + 1);
        console.log(index)
        if (index !== -1) {
            console.log('aqui')
            const motivo = updatedVendedores[index].motivoSelecionado
            updatedVendedores[index].motivoSelecionado = '';
            updatedVendedores[index].motivosNaoVenda[motivo]++;
            setVendedores(updatedVendedores);
        }
    }

    const avancarVendedor = () => {
        const vendedoresDisponiveis = vendedores.filter(vendedor => vendedor.espera === 0);

        if (vendedoresDisponiveis.length === 0) {
            // Se todos os vendedores estiverem ocupados, não fazemos nada
            return;
        }

        let proximoIndex = (vendedorDaVez + 1) % vendedores.length;

        // Enquanto o próximo vendedor estiver ocupado, avançamos para o próximo
        while (vendedores[proximoIndex].espera > 0) {
            proximoIndex = (proximoIndex + 1) % vendedores.length;
        }

        setVendedorDaVez(proximoIndex);
    };

    const atenderCliente = () => {
        const updatedVendedores = [...vendedores];
        updatedVendedores[vendedorDaVez].espera++;
        setVendedores(updatedVendedores);
        avancarVendedor();
    };

    const vender = (vendedor) => {
        if (vendedor) {
            vendedor = vendedor - 1
            const updatedVendedores = [...vendedores];
            updatedVendedores[vendedor].vendas++;
            const vendedoresDisponiveis = vendedores.filter(vendedor => vendedor.espera === 0);
            if (updatedVendedores[vendedor].espera > 0) {
                updatedVendedores[vendedor].espera = 0; // Resetando a espera apenas se houver espera
            }

            setVendedores(updatedVendedores);

            if (vendedoresDisponiveis.length === 0) {
                avancarVendedor();
            }
        }
        else {
            const updatedVendedores = [...vendedores];
            updatedVendedores[vendedorDaVez].vendas++;
            if (updatedVendedores[vendedorDaVez].espera > 0) {
                updatedVendedores[vendedorDaVez].espera = 0; // Resetando a espera apenas se houver espera
            }
            setVendedores(updatedVendedores);
            avancarVendedor();
        }
    };

    const nvendeu = (vendedorId) => {
        if (vendedorId) {
            vendedorId = vendedorId - 1
            const updatedVendedores = [...vendedores];
            updatedVendedores[vendedorId].nvendeu++;
            const vendedoresDisponiveis = vendedores.filter(vendedor => vendedor.espera === 0);
            if (updatedVendedores[vendedorId].espera > 0) {
                updatedVendedores[vendedorId].espera = 0; // Resetando a espera apenas se houver espera
            }
            if (vendedoresDisponiveis.length === 0) {
                avancarVendedor();
            }
            setVendedores(updatedVendedores);
            handleRegistarNVenda(vendedorId)
        }
        else {
            const updatedVendedores = [...vendedores];
            updatedVendedores[vendedorDaVez].nvendeu++;
            if (updatedVendedores[vendedorDaVez].espera > 0) {
                updatedVendedores[vendedorDaVez].espera = 0; // Resetando a espera apenas se houver espera
            }
            setVendedores(updatedVendedores);
            handleRegistarNVenda(vendedorId)
            avancarVendedor();
        }
    };

    // Calculando total de vendas
    const totalVendas = vendedores.reduce((total, vendedor) => total + vendedor.vendas, 0);

    // Calculando motivos de não venda
    const motivosNaoVendaData = {
        labels: ["Preço alto", "Indisponibilidade de produto", "Outros"],
        datasets: vendedores.map(vendedor => ({
            label: vendedor.nome,
            data: Object.values(vendedor.motivosNaoVenda),
            backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.6)`,
            borderColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 1)`,
            borderWidth: 1,
        })),
    };

    return (
        <div className="container mt-4">
            <h2 className="text-center mb-4">Vendedores da Vez</h2>
            <div align="center">
                <Card className='w-50 text-center mb-3'>
                    <Card.Text className=''>
                        {vendedores[0].motivosNaoVenda['Preço alto']}
                        <p>Vendedor da vez: {vendedores[vendedorDaVez].nome}</p>
                        <p>Vendas deste vendedor: {vendedores[vendedorDaVez].vendas}</p>
                        <p>Clientes atendidos sem compra: {vendedores[vendedorDaVez].espera}</p>
                    </Card.Text>
                </Card>
            </div>
            <Button onClick={atenderCliente} variant="warning" className='mb-3 me-3'>Atendendo Cliente</Button>
            <Row>
                {vendedores.map((vendedor, index) => (
                    <Col key={vendedor.id} sm={6} md={3}>
                        <Card className={`mb-3 ${index === vendedorDaVez ? 'bg-info' : ''}`} onClick={() => setVendedorSelecionado(vendedor.id)}
                            style={{ cursor: 'pointer' }}>
                            <Card.Body>
                                <Card.Title>{vendedor.nome}</Card.Title>
                                <Card.Text>Vendas: {vendedor.vendas}</Card.Text>
                                <Card.Text>Não Vendeu: {vendedor.nvendeu}</Card.Text>
                                <Card.Text>Espera: {vendedor.espera}</Card.Text>
                            </Card.Body>
                            <Card.Text>
                                <select
                                    value={vendedor.motivoSelecionado}
                                    onChange={(e) => handleMotivoSelecionado(vendedor.id, e.target.value)}
                                    className='form-control' 
                                    id={`select`+vendedor.id}
                                >
                                    <option value="">Selecione um motivo</option>
                                    <option value="Preço alto">Preço alto</option>
                                    <option value="Indisponibilidade de produto">Indisponibilidade de produto</option>
                                    <option value="Outros">Outros</option>
                                </select>
                            </Card.Text>
                        </Card>

                        {vendedorSelecionado === vendedor.id && (
                            <div className="text-center">
                                <Button onClick={() => vender(vendedor.id)} variant="primary" className='mb-3 me-3'>Vender</Button>
                                <Button onClick={() => nvendeu(vendedor.id)} variant="danger" className='mb-3'>Não Vendeu</Button>
                            </div>
                        )}
                    </Col>
                ))}
            </Row>
            <div>
                <h2>Total de Vendas</h2>
                <p>Total: {totalVendas}</p>
            </div>
            <div>
                <h2>Motivos de Não Venda</h2>
                <Bar data={motivosNaoVendaData} />
            </div>
        </div>
    );
}

export default VendedorDaVez;
