import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import axios from 'axios'

function ContaColunaNovo() {
  const [login, setLogin] = useState('')
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [ativo, setAtivo] = useState(false)

  const { userId } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const config = {
            headers: {
              'Authorization': `Token ${token}`,
            }
          };
        const response = await axios.get(`${process.env.REACT_APP_URLBK}/v-1/vendedores/editar_vendedor/${userId}`, config)
        const userData = response.data
        setLogin(userData.login)
        setNome(userData.nome)
        setEmail(userData.email)
        setPassword(userData.password)
        setAtivo(userData.ativo)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = sessionStorage.getItem('token');
      const data = {
        login,
        nome,
        email,
        password,
        ativo,
      };

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_URLBK}/v-1/vendedores/editar_vendedor/${userId}`,
        data,
        { headers }
      );

      alert(response.data.message);
      
      window.location = '/vendedor'

    } catch (error) {
      console.error(error);
      alert('Erro ao editar usuário');
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      if(name === 'ativo'){
        setAtivo(checked)
      }
    } else {
      switch (name) {
        case 'login':
          setLogin(value)
          break
        case 'nome':
          setNome(value)
          break
        case 'email':
          setEmail(value)
          break
        case 'password':
          setPassword(value)
          break
        default:
          break
      }
    }
  }

  
 
  const handleVoltar = () => {
    window.location = '/vendedor'
  }

  return (
    <div className='configuracao-usuario-novo-div-form'>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className='bold'>Login</Form.Label>
        <Form.Control type="text" name="login" value={login} onChange={handleChange} placeholder="Login" required />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
        <Form.Label className='bold'>Nome</Form.Label>
        <Form.Control type="text" name="nome" value={nome} onChange={handleChange} placeholder="Nome" required />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
        <Form.Label className='bold'>E-mail</Form.Label>
        <Form.Control type="email" name="email" value={email} onChange={handleChange} placeholder="E-mail" required />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
        <Form.Label className='bold'>Senha</Form.Label>
        <Form.Control type="password" name="password" value={password} onChange={handleChange} placeholder="Senha" required />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
        <Form.Check type="checkbox" name="ativo" checked={ativo} onChange={handleChange} label={'Ativo'} />
      </Form.Group>
      <Button className='usuarionovo-button-criar' onClick={handleSubmit}>Editar</Button>
      <Button className='usuarionovo-button-voltar' onClick={handleVoltar}>Voltar</Button>
    </div>
  )
}

export default ContaColunaNovo