import { faCalculator,  faIdCard, faPlusMinus,  faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

function DadosPlataforma() {
    return (
        <Row className='dashboard-dadosplataforma-title '>
            <Col>
                <Row>
                    <h3><FontAwesomeIcon icon={faUsers} /> Clientes</h3>
                    <div className=''>
                        <Card className='dashboard-dadosplataforma-cards'>
                            <div className='dashboard-dadosplataforma-cards-row'><h5>1</h5><p className='dashboard-dadosplataforma-cards-row-percent'>+0%</p> </div>
                            <p>Participantes</p>
                        </Card>
                     
                    </div>
                </Row>
            </Col>
            <Col>
                <Row>
                    <h3><FontAwesomeIcon icon={faIdCard} /> Vendas</h3>
                    <div className=''>
                        <Card className='dashboard-dadosplataforma-cards'>
                            <div className='dashboard-dadosplataforma-cards-row'><h5>1</h5><p className='dashboard-dadosplataforma-cards-row-percent'>+0%</p> </div>
                            <p>Participantes</p>
                        </Card>
                        
                    </div>
                </Row>
            </Col>
            <Col>
                <Row>
                    <h3><FontAwesomeIcon icon={faPlusMinus} /> Médias</h3>
                    <div className=''>
                        <Card className='dashboard-dadosplataforma-cards'>
                            <div className='dashboard-dadosplataforma-cards-row'><h5>1</h5><p className='dashboard-dadosplataforma-cards-row-percent'>+0%</p> </div>
                            <p>Participantes</p>
                        </Card>
                       
                    </div>
                </Row>
            </Col>
            <Col>
                <Row>
                    <h3><FontAwesomeIcon icon={faCalculator} /> Satisfação </h3>
                    <div className=''>
                        <Card className='dashboard-dadosplataforma-cards'>
                            <div className='dashboard-dadosplataforma-cards-row'><h5>1</h5><p className='dashboard-dadosplataforma-cards-row-percent'>+0%</p> </div>
                            <p>Participantes</p>
                        </Card>
                        
                    </div>
                </Row>
            </Col>

        </Row>
    )
}

export default DadosPlataforma