import { faGifts} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function AniversariantesColuna1() {
  return (
    <>
      <div className='icon-text'>
        <FontAwesomeIcon icon={faGifts} size='2x' />
        <h3> Aniversariantes</h3>
      </div>
      <p>Este relatório apresenta os aniversariantes no mês corrente.</p>
    </>
  )
}

export default AniversariantesColuna1