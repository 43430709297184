import React from 'react'
import GenericBoxView from '../../components/ViewGeneric/GenericBoxView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import WhatsappColuna1 from '../../components/Whatsapp/WhatsappColuna1'
import WhatsappColuna2 from '../../components/Whatsapp/WhatsappColuna2'


function Whatsapp() {
  return (
    <GenericBoxView 
    icon={<FontAwesomeIcon icon={faWhatsapp} size='2x' />} 
    title={'Whatsapp'} 
    titleBox={'Gerenciamento de disparo do Whatsapp dos clientes'} 
    coluna1={<WhatsappColuna1/>} 
    coluna2={<WhatsappColuna2/>}/>
  )
}

export default Whatsapp