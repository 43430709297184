import React, { useEffect, useState } from 'react'
import { Button, Card, Form, Image, Modal, Table } from 'react-bootstrap'
import './Caixa.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faMoneyBill, faMoneyBillTransfer, faTicket, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { faChartBar } from '@fortawesome/free-regular-svg-icons'
import InputMask from 'react-input-mask';
import logo from '../../assets/logo.png'
import axios from 'axios'
import CadastroCliente from '../../components/modelsGenericos/CadastroCliente'
import VoucherCliente from '../../components/modelsGenericos/VoucherCliente'
import fetchCashback from '../../services/configuracao/cashback'
import fetchClientes from '../../services/clientes/previsualizacao'
import { format } from 'date-fns';
import adicionar from '../../services/cashback/adicionar'
import resgatar from '../../services/cashback/resgatar'
import compraAddResgatar from '../../services/cashback/add-resgatar'
import Porco from '../../assets/buttons/porco.png'
import Resgatar from '../../assets/buttons/resgatar.png'
import Voucher from '../../assets/buttons/voucher.png'
import Cadastrar from '../../assets/buttons/cadastrar.png'
import Historico from '../../assets/buttons/historico.png'
import Sair from '../../assets/buttons/sair.png'
// import CurrencyInputField from 'react-currency-input-field';

function Caixa() {

    const initialClienteData = {
        cpf: '',
        valor: '',
        valorNumber: 0,
    };
    const [cashbackConfig, setCashbackConfig] = useState({
        ativar: false,
        percentual: '',
        centavos: false,
        minimo: '',
        limite: '',
    })
    const [pontosAtuaisCliente, setPontosAtuaisCliente] = useState({
        pontos: 0,
        cashback: 0,
    });

    const [clienteData, setClienteData] = useState(initialClienteData);
    const [showCadastro, setShowCadastro] = useState(false);
    const [showVoucher, setShowVoucher] = useState(false);
    const [showHistorico, setShowHistorico] = useState(false);
    const [preVisualizacao, setPreVisualizacao] = useState();
    const [showNotificationCompra, setShowNotificationCompra] = useState(false);
    const [historicos, setHistoricos] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const cashbackConfigAtual = await fetchCashback();
                setCashbackConfig(() => cashbackConfigAtual);
            } catch (error) {
                console.error('Erro ao buscar configuração de cashback:', error);
            }
        }

        fetchData(); // Chama a função assíncrona imediatamente

    }, []);

    useEffect(() => {
        // Remove caracteres não numéricos do CPF
        const numericCPF = clienteData.cpf.replace(/\D/g, '');

        // Verifica se o CPF tem 11 dígitos
        if (numericCPF.length === 11) {
            fetchClientes(
                clienteData.cpf,
                cashbackConfig,
                setPontosAtuaisCliente,
                setPreVisualizacao,
                showCadastroModal
            );
        }
        else {
            setPreVisualizacao('')
            setPontosAtuaisCliente({
                pontos: 0,
                cashback: 0,
            })
        }
    }, [clienteData.cpf]);

    const handleInputChange = (e) => {
        var { name, value } = e.target;
        if (name === "valor") {
            var valorString = String(value).replace("R$", "").trim();
            var valorNumber = parseFloat(valorString.replace(/\./g, "").replace(",", "."));
            setClienteData(prevData => ({
                ...prevData,
                valorNumber: valorNumber,
            }));
        }
        setClienteData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

    };

    var showCadastroModal = () => {
        setShowCadastro(true);
    }
    var showVoucherModal = () => {
        setShowVoucher(true);
    }

    const fetchHistoricos = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            };
            var cpfTratado = clienteData.cpf
            var dados = {
                "cpf": cpfTratado,
            }
            const response = await axios.post(`${process.env.REACT_APP_URLBK}/v-1/historico/movimentacao`, dados, { headers }); // Substitua com a URL correta
            setHistoricos(response.data);
        } catch (error) {
            console.error('Erro ao buscar dados do histórico:', error);
        }
    };

    var showHistoricoModal = () => {
        if(clienteData.cpf){
            fetchHistoricos();
            setShowHistorico(true);
        }else{
            alert("Digite um cpf valido")
        }
       
    }

    const handleClose = () => {
        setShowNotificationCompra(false);
        setShowCadastro(false);
        setShowVoucher(false);
        setShowHistorico(false);
    };

    const handleVoltar = () => {
        if (sessionStorage.getItem('role') === "1") {
            window.location.href = '/'
        } else {
            localStorage.clear()
            sessionStorage.clear()
            window.location.href = '/'
        }
    };

    function mascaraMoeda(event) {
        const onlyDigits = event.target.value
            .split("")
            .filter(s => /\d/.test(s))
            .join("")
            .padStart(3, "0")
        const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2)
        event.target.value = maskCurrency(digitsFloat)
    }

    function maskCurrency(valor, locale = 'pt-BR', currency = 'BRL') {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency
        }).format(valor)
    }

    const handleAdd = async () => {
        var response = await adicionar(clienteData)
        
        try{
            alert('Erro ao resgatar pontos o cliente: ' + response.response.data['error']);
        }catch(error){
            alert('Pontos resgatados com sucesso:', response.data);
            setClienteData(initialClienteData);
        }
        handleClose();
    };

    const handleResgatePontos = async (event) => {
        event.preventDefault();
        var response = await resgatar(clienteData)
        try{
            alert('Erro ao resgatar pontos o cliente: ' + response.response.data['error']);
        }catch(error){
            alert('Pontos resgatados com sucesso:', response.data);
            setClienteData(initialClienteData);
        }
    };

    const handleCashbackReducao = async () => {
        var response = await compraAddResgatar(clienteData)
        try{
            alert('Erro ao resgatar pontos o cliente: ' + response.response.data['error']);
        }catch(error){
            alert('Pontos resgatados com sucesso:', response.data);
            setClienteData(initialClienteData);
        }
        handleClose();
    };

    const handleCPFChange = async (event) => {
        const cpf = event.target.value;

        if (cpf.length === 14) {
            try {
                const token = sessionStorage.getItem('token');
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                };

                const response = await axios.get(
                    `${process.env.REACT_APP_URLBK}/v-1/clientes/verificacao_pontos`,
                    { headers }
                );

                // Aqui você pode tratar a resposta do servidor
                const clienteInfo = response.data;

                // Atualizar os dados do clienteData com as informações recebidas
                setClienteData((prevData) => ({
                    ...prevData,
                    pontos: clienteInfo.pontos,
                    cashback: clienteInfo.cashback,
                }));
            } catch (error) {
                console.error('Erro ao buscar informações do cliente:', error);
            }
        } else {
            // Limpar os dados do clienteData caso o CPF não tenha 14 dígitos
            setClienteData((prevData) => ({
                ...prevData,
                pontos: '',
                cashback: '',
            }));
        }
    };



    return (
        <>
            <div className='caixa-container-logo'>
                <Image src={logo} />
            </div>
            <div className='caixa-div-form'>
                <Form className='caixa-form'>
                    <Form.Group className="mb-3 " controlId="exampleForm.ControlInput5">
                        <InputMask
                            mask="999.999.999-99"
                            maskChar=" "
                            className="form-control"
                            name="cpf"
                            placeholder="Digite o CPF do cliente"
                            value={clienteData.cpf}
                            onChange={handleInputChange}
                            onInput={handleCPFChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Control
                            type="text"
                            placeholder="R$ 0,00"
                            onInput={(event) => mascaraMoeda(event)}
                            name="valor"
                            value={clienteData.valor}
                            onChange={handleInputChange}
                            required
                        />

                    </Form.Group>

                    {preVisualizacao && (
                        <>
                            <hr></hr>
                            <div className='card-previsualizacao-caixa bg-white rounded'>
                                {preVisualizacao}
                            </div>
                        </>
                    )}
                </Form>
            </div>
            <div className='caixa-container-footer'>
                <div className='caixa-container-buttons'>
                    <Card onClick={() => setShowNotificationCompra(true)}>
                        <Card.Title className='bg-blue clicavel'><Image src={Porco} /></Card.Title>
                        <Button className="btn button-prime-orange-caixa">Pontuar</Button>
                    </Card>
                    <Card onClick={handleResgatePontos}>
                        <Card.Title className='bg-blue clicavel'><Image src={Resgatar} /></Card.Title>
                        <Button className="btn button-prime-orange-caixa">Resgatar</Button>
                    </Card>
                    <Card onClick={showVoucherModal}>
                        <Card.Title className='bg-blue clicavel'><Image src={Voucher} /></Card.Title>
                        <Button className="btn button-prime-orange-caixa">Voucher</Button>
                    </Card>
                </div>
                <div className='caixa-container-buttons'>
                    <Card onClick={showCadastroModal}>
                        <Card.Title className='bg-blue clicavel'><Image src={Cadastrar} /></Card.Title>
                        <Button className="btn button-prime-orange-caixa">Cadastrar</Button>
                    </Card>
                    <Card onClick={showHistoricoModal}>
                        <Card.Title className='bg-blue clicavel'><Image src={Historico} /></Card.Title>
                        <Button className="btn button-prime-orange-caixa">Historico</Button>
                    </Card>
                    <Card onClick={handleVoltar}>
                        <Card.Title className='bg-blue clicavel'><Image src={Sair} /></Card.Title>
                        <Button className="btn button-prime-orange-caixa">Sair</Button>
                    </Card>
                </div>
            </div>
            <Modal show={showNotificationCompra} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Utilizar o cashback?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h5><strong>Deseja utilizar os pontos do Cliente Prime?</strong> <br></br>
                            <strong>Pontos:</strong> {(Math.trunc(pontosAtuaisCliente.pontos * 100) / 100).toFixed(2)}<br></br>
                            <strong>Cashback:</strong> R$ {(Math.trunc(pontosAtuaisCliente.cashback * 100) / 100).toFixed(2)}<br></br>
                            <strong>Valor da compra:</strong> {clienteData.valor}<br></br>
                            <strong>Valor Final será:</strong> {Math.max(0, clienteData.valorNumber - pontosAtuaisCliente.cashback).toFixed(2)}</h5>
                    </div>
                    <br></br>
                    <Button onClick={() => handleCashbackReducao()} >Utilizar Cashback</Button>{'  '}
                    <Button variant='danger' onClick={() => handleAdd()}>Não utilizar Cashback</Button>
                </Modal.Body>
            </Modal>
            <Modal show={showCadastro} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Cadastro Cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CadastroCliente handleClose={handleClose} />
                </Modal.Body>
            </Modal>
            <Modal show={showVoucher} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Registro de Voucher</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <VoucherCliente handleClose={handleClose} />
                </Modal.Body>
            </Modal>
            <Modal show={showHistorico} onHide={handleClose} className='modal-full'>
                <Modal.Header closeButton>
                    <Modal.Title>Historico Cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Consumidor</th>
                                <th>Ação</th>
                                <th>Quantidade</th>
                                <th>Data</th>
                                <th>Agente</th>
                                <th>Local</th>
                            </tr>
                        </thead>
                        <tbody>
                            {historicos.map((historico) => (
                                <tr key={historico.id}>
                                    <td>{historico.cliente}</td>
                                    <td className={`tipo-${historico.tipo.toLowerCase().replace(' ', '-')}`}>{historico.tipo}</td>
                                    <td>{historico.pontos}</td>
                                    <td>{format(new Date(historico.at_create), 'dd/MM/yyyy HH:mm:ss')}</td>
                                    <td> {historico.usuario}</td>
                                    <td>{historico.local}</td>
                                    
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default Caixa