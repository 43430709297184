import React, { useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import EditarCliente from '../../services/PC/contaEditor'
import axios from 'axios'

function Conta() {
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [cpf, setCpf] = useState('')
    const [dataDeNascimento, setDataDeNascimento] = useState('')
    const [whatsapp, setWhatsapp] = useState('')

    const handleChange = (e) => {
        const { name, value,  } = e.target
        switch (name) {
            case 'nome':
                setNome(value)
                break
            case 'whatsapp':
                setWhatsapp(value)
                break
            case 'dataDeNascimento':
                setDataDeNascimento(value)
                break
            case 'email':
                setEmail(value)
                break
            case 'password':
                setPassword(value)
                break

            default:
                break
        }
    }
    const clienteDados = async() => {
        try {
            const token = sessionStorage.getItem('token');
            const cpf = sessionStorage.getItem('cpf');
            const headers = {
              'Content-Type': 'application/json',
            };
            var dados = {
              "cpf": cpf,
              "token": token,
            }
            const response = await axios.post(`${process.env.REACT_APP_URLBK}/v-1/areacliente/conta/`, dados, { headers }); // Substitua com a URL correta
            setCpf(response.data[0]['cpf'])
            setNome(response.data[0]['nome'])
            setEmail(response.data[0]['email'])
            setDataDeNascimento(response.data[0]['data_de_nascimento'])
            setWhatsapp(response.data[0]['whatsapp'])
            setPassword(response.data[0]['password'])
      
          } catch (error) {
            console.error('Erro ao buscar dados da Conta:', error);
          }

    }
    useEffect(() => {
      clienteDados();
    }, [])
    

    const handleSubmit = (e) => {
        const token = sessionStorage.getItem('token');
        const cpfToken = sessionStorage.getItem('cpf');
        e.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            // Exibe a mensagem de erro para o campo de e-mail
            alert('Por favor, insira um e-mail válido.');
            return;
        }
        if (!cpf || !nome || !email || !password || !whatsapp || !dataDeNascimento) {
            // Exibe a mensagem de erro
            alert('Por favor, preencha todos os campos.');
            return;
        } else {

            const data = {
                nome: nome,
                email: email,
                password: password,
                cpf: cpfToken,
                dataDeNascimento: dataDeNascimento,
                whatsapp: whatsapp,
                token:token
            };
            EditarCliente(data);
        }
    };
   

    return (
        <div className='container-pc-cliente'>
          <div className='indiqueumAmigo'>
        <div className='configuracao-usuario-novo-div-form'>
            <h1 className="mb-3 mt-5"><strong>Conta do Cliente</strong></h1>
            <Form.Group className="mb-3 mt-5" controlId="exampleForm.ControlInput2">
                <Form.Label className='bold'>Nome</Form.Label>
                <Form.Control type="text" name="nome" value={nome} onChange={handleChange} placeholder="Nome" required />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                <Form.Label className='bold'>E-mail</Form.Label>
                <Form.Control type="email" name="email" value={email} onChange={handleChange} placeholder="E-mail" required />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className='bold'>Data de Nascimento</Form.Label>
                <Form.Control type="text" name="dataDeNascimento" value={dataDeNascimento} onChange={handleChange} placeholder="dataDeNascimento" required />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                <Form.Label className='bold'>Whatsapp</Form.Label>
                <Form.Control type="text" name="whatsapp" value={whatsapp} onChange={handleChange} placeholder="whatsapp" required />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                <Form.Label className='bold'>CPF</Form.Label>
                <Form.Control type="text" name="cpf" value={cpf} disabled placeholder="cpf" required />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                <Form.Label className='bold'>Senha</Form.Label>
                <Form.Control type="password" name="password" value={password} onChange={handleChange} placeholder="Senha" required />
            </Form.Group>
            <Button className='button-prime-orange-login' onClick={handleSubmit}>Editar</Button>
        </div>
        </div>
        </div>
    )
}

export default Conta