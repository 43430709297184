import React, { useState } from 'react'
import GenericBoxView from '../../components/ViewGeneric/GenericBoxView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUserCheck } from '@fortawesome/free-solid-svg-icons'
import RegistroColuna1 from '../../components/RegistroPonto/RegistroColuna1'
import RegistroColuna2 from '../../components/RegistroPonto/RegistroColuna2'
import { Card, Table,  } from 'react-bootstrap'
import axios from 'axios';

function RegistroPonto() {
    const [clientes, setClientes] = useState([]);
    const [filtros, setFiltros] = useState({
        mesano: null,
        funcionario: '',
    });



    const fetchClientes = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            };

            const response = await axios.post(`${process.env.REACT_APP_URLBK}/v-1/ponto/relatorio`,
                filtros,
                {
                    headers

                }
            );
            setClientes(response.data);
        } catch (error) {
            console.error('Erro ao buscar clientes:', error);
        }
    };
    const test = () => {
        if (filtros.mesano == null || filtros.funcionario === '') {
            alert('Dados Incompletos por favor preencher os dois campos')
        } else {
            fetchClientes();

        }
    }

    return (
        <>
            <GenericBoxView
                icon={<FontAwesomeIcon icon={faUserCheck} size='2x' />}
                title={'Registro Pontos Digitais'}
                titleBox={'Registro dos Pontos Digitais'}
                coluna1={<RegistroColuna1 />}
                coluna2={<RegistroColuna2 onFiltrosChange={setFiltros} onSearch={test} />} />
            <hr />
            <Card>
                <Card.Title className='cadastroNovoscliente-card-title'><h4>Resultados</h4></Card.Title>
                <Card.Body>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Entrada</th>
                                <th>Almoço</th>
                                <th>Retorno Almoço</th>
                                <th>Saida</th>
                                <th>Funcionario</th>
                                <th>Geolocalização</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clientes.map((cliente) => (
                                <tr>
                                    <td>{cliente.formatted_data}</td>
                                    <td>{cliente.formatted_entrada}</td>
                                    <td>{cliente.formatted_almoco}</td>
                                    <td>{cliente.formatted_ralmoco}</td>
                                    <td>{cliente.formatted_saida}</td>
                                    <td>{cliente.funcionario}</td>
                                    <td>{cliente.geolocalizacao}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    )
}

export default RegistroPonto