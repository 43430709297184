import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  RadialLinearScale,
  ArcElement,
} from 'chart.js';
import { Line, Bar, PolarArea } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  RadialLinearScale,
  ArcElement,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        color: 'black', // Change the color of the dataset labels to white
      },
    },
    title: {
      display: true,
      text: 'Clientes',
      color: 'black',
    },
  },
  scales: {
    x: {
      ticks: {
        color: 'black', // Change X axis ticks color to white
      },
      grid: {
        color: 'black', // Change X axis grid color to white
      },
    },
    y: {
      ticks: {
        color: 'black', // Change Y axis ticks color to white
      },
      grid: {
        color: 'black', // Change Y axis grid color to white
      },
    },
  },
};


const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Pontos 1',
      data: [65, 59, 80, 91, 56, 55, 60],
      borderColor: 'red',
      backgroundColor: 'red',
    },
    {
      label: 'Cashback 2',
      data: [165, 159, 180, 191, 156, 155, 160],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgb(53, 162, 235)',
    },
  ],
};

export const datapolar = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.5)',
        'rgba(54, 162, 235, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        'rgba(75, 192, 192, 0.5)',
        'rgba(153, 102, 255, 0.5)',
        'rgba(255, 159, 64, 0.5)',
      ],
      borderWidth: 1,
    },
  ],
};

export function GraficoPlataforma() {
  return <Line options={options} data={data} className='mt-5'/>;
}
export function GraficoPlataforma2() {
  return <PolarArea options={options} data={datapolar} className='mt-5'/>;
}
