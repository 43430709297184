import React from 'react'
import GenericBoxViewOneC from '../../components/ViewGeneric/GenericBoxViewOneC'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import VendedoresColuna1 from '../../components/Vendedores/VendedoresColuna1'

function Vendedor() {
  return (
    <GenericBoxViewOneC 
    icon={<FontAwesomeIcon icon={faUserPlus} size='2x' />} 
    title={'Vendedor'} 
    titleBox={'Gerenciamento de Vendedores'} 
    coluna1={<VendedoresColuna1/>} 
    />
  )
}

export default Vendedor