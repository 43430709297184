const contaEditor = async (data) => {
    fetch(process.env.REACT_APP_URLBK + '/v-1/areacliente/conta/', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(result => {
        if(result['message'] === 'UNIQUE constraint failed: accounts_user.email'){
          alert('Email já Existe') 
        }else{
        alert(result['message'])
        window.location = '/cliente/conta' 
      }
      })
      .catch(error => {
        console.error('Erro:', error); // Lida com erros de chamada à API
      });
  };
  
  export default contaEditor;
  
  