import { format } from 'date-fns';
import React, { useState, useEffect } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap'
import axios from 'axios'
import './areadocliente.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCartShopping } from '@fortawesome/free-solid-svg-icons';

function AreaDoCliente() {
  const [historicos, setHistoricos] = useState([]);
  const [saldo, setSaldo] = useState([]);

  const fetchHistoricos = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const cpf = sessionStorage.getItem('cpf');
      const headers = {
        'Content-Type': 'application/json',
      };
      var dados = {
        "cpf": cpf,
        "token": token,
      }
      const response = await axios.post(`${process.env.REACT_APP_URLBK}/v-1/areacliente/extrato/`, dados, { headers }); // Substitua com a URL correta
      setHistoricos(response.data);
    } catch (error) {
      console.error('Erro ao buscar dados do histórico:', error);
    }
  };
  const fetchSaldo = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const cpf = sessionStorage.getItem('cpf');
      const headers = {
        'Content-Type': 'application/json',
      };
      var dados = {
        "cpf": cpf,
        "token": token,
      }
      const response = await axios.post(`${process.env.REACT_APP_URLBK}/v-1/areacliente/saldo/`, dados, { headers }); // Substitua com a URL correta
      setSaldo(response.data);
    } catch (error) {
      console.error('Erro ao buscar dados do histórico:', error);
    }
  };
  useEffect(() => {
    fetchHistoricos();
    fetchSaldo();
  }, [])

  return (
    <div className='container-pc-cliente'>
      <div className='areadocliente'>
      <Card className='card-extrato'>
        <Card.Title>
          <h1><strong>Extrato</strong></h1>
        </Card.Title>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Ação</th>
                <th>Quantidade</th>
                <th>Data</th>
                <th>Empresa</th>
              </tr>
            </thead>
            <tbody>
              {historicos.map((historico) => (
                <tr key={historico.id}>
                  <td className={`tipo-${historico.tipo.toLowerCase().replace(' ', '-')}`}>{historico.tipo}</td>
                  <td>{historico.pontos}</td>
                  <td>{format(new Date(historico.at_create), 'dd/MM/yyyy HH:mm:ss')}</td>
                  <td>{historico.empresa}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Card className='card-extrato'>
        <Card.Title className='m-1'>
          <h1 className='mb-2'><strong>Como Funciona?</strong></h1>
          <Row>
            <Col className='col-3'>
              <FontAwesomeIcon icon={faCartShopping} size='4x' className='mb-3' />
            </Col>
            <Col>
              <h4 className='mb-3'><strong>Compre e Ganhe</strong></h4>
              <p>Aproveite suas compras ao máximo! Identifique-se nas transações e desfrute de vantagens exclusivas. A cada R$1 gasto, você acumula 1 ponto, que pode ser utilizado em benefícios incríveis.</p>
            </Col>
          </Row>


        </Card.Title>
      </Card>
      <Card className='card-extrato'>
        <Card.Title>
          <h1><strong>Saldo</strong></h1>
        </Card.Title>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Empresa</th>
                <th>Pontos</th>
                <th>Cashback</th>
              </tr>
            </thead>
            <tbody>
              {saldo.map((saldo) => (
                <tr key={saldo.id}>
                  <td>{saldo.empresa}</td>
                  <td>{saldo.pontos}</td>
                  <td>R$ {String(saldo.cashback).replace('.',',')}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      </div>
    </div>
  )
}

export default AreaDoCliente