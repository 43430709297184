import {  faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function CashbackColuna1() {
  return (
    <>
      <div className='icon-text'>
        <FontAwesomeIcon icon={faMoneyBill} size='2x' />
        <h3> Cashback</h3>
      </div>
      <h5 style={{'font-size':'15px', 'marginBottom':'0.3rem'}}>Ofereça o programa de cashback exclusivo aos seus clientes!</h5>
      <p style={{'font-size':'12px', 'marginBottom':'0.1rem'}}>Estamos prestes a ativar o cashback em sua loja com apenas algumas configurações.</p>
      <p style={{'font-size':'12px', 'marginBottom':'0.1rem'}}>Aumente o engajamento e a fidelidade dos seus clientes ao permitir que eles recebam uma porcentagem do valor gasto de volta em suas compras.</p>
      <p style={{'font-size':'12px', 'marginBottom':'0.1rem'}}>Entre em contato conosco agora mesmo para saber mais sobre como implementar essa emocionante funcionalidade e se destacar da concorrência.</p>
      <p style={{'font-size':'12px', 'marginBottom':'0.1rem'}}>Estamos aqui para ajudar a impulsionar seus negócios com o poder do cashback!</p>
    </>
  )
}

export default CashbackColuna1