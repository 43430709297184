import React from 'react'
import GenericBoxViewOneC from '../../components/ViewGeneric/GenericBoxViewOneC'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import UsuarioColunaEditar from '../../components/Usuarios/UsuarioColunaEditar'

function Usuarios_Editar() {
  return (
    <GenericBoxViewOneC 
    icon={<FontAwesomeIcon icon={faUserPlus} size='2x' />} 
    title={'Usuarios'} 
    titleBox={'Gerenciamento da Conta'} 
    coluna1={<UsuarioColunaEditar/>} 
    />
  )
}

export default Usuarios_Editar