import { faCheckToSlot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function PontuarColuna1() {
  return (
    <>
      <div className='icon-text'>
          <FontAwesomeIcon icon={faCheckToSlot} size='2x' />
          <h3>Pontuar</h3>
      </div>
      <p>Utilize os campos ao lado para pontuar, resgatar, cadastrar e resgatar os vouchers de seus consumidores</p>
    </>
  )
}

export default PontuarColuna1