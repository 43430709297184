import React from 'react'
import { Button, Card,  Form} from 'react-bootstrap'
import axios from 'axios'
import InputMask from 'react-input-mask';
import { parseISO, format } from 'date-fns'

function CadastroCliente() {
  const initialClienteData = {
    nome: '',
    cpf: '',
    sexo: '',
    data_de_nascimento: '',
    email: '',
    telefone: '',
  };

  const [clienteData, setClienteData] = React.useState(initialClienteData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClienteData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(clienteData)



    try {
      const token = sessionStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      };

      // Fazer parsing da data para o formato JavaScript Date
      const parsedDate = parseISO(clienteData.data_de_nascimento);

      // Formatar a data para o formato "dd/MM/yyyy"
      const formattedDate = format(parsedDate, 'dd/MM/yyyy');

      // Criar uma cópia do objeto clienteData com a data formatada
      const formattedClienteData = {
        ...clienteData,
        data_de_nascimento: formattedDate,
      };
      console.log(formattedClienteData)

      const response = await axios.post(
        `${process.env.REACT_APP_URLBK}/v-1/clientes/cadastro_clientes`,
        formattedClienteData,
        { headers }
      );
      // Aqui você pode tratar a resposta se necessário
      alert('Cliente cadastrado com sucesso:', response.data);
      setClienteData(initialClienteData);
    } catch (error) {
      alert('Erro ao cadastrar o cliente: ' + error.response.data['error']);
    }
  };

  return (
    <>
    
      <Card>
        <Card.Body>

              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label className='bold'>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    name="nome"
                    placeholder="Nome"
                    value={clienteData.nome}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                  <Form.Label className='bold'>CPF</Form.Label>
                  <InputMask
                    mask="999.999.999-99"
                    maskChar=" "
                    className="form-control"
                    name="cpf"
                    placeholder="Digite o CPF do cliente"
                    value={clienteData.cpf}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                  <Form.Label className='bold'>Sexo</Form.Label>
                  <Form.Control
                    as="select"
                    name="sexo"
                    value={clienteData.sexo}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Selecione o Sexo</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Feminino">Feminino</option>
                    <option value="Outros">Outros</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                  <Form.Label className='bold'>Data de Nascimento</Form.Label>
                  <Form.Control
                    type="date"
                    name="data_de_nascimento"
                    placeholder="Data de Nascimento"
                    value={clienteData.data_de_nascimento}
                    onChange={handleInputChange}
                    required
                    max={new Date().toISOString().split("T")[0]} // Impede datas futuras
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                  <Form.Label className='bold'>E-mail</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    value={clienteData.email}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                  <Form.Label className='bold'>Telefone com DDD</Form.Label>
                  <InputMask
                    mask="(99) 99999-9999"
                    maskChar=" "
                    className="form-control"
                    name="telefone"
                    placeholder="Telefone com DDD"
                    value={clienteData.telefone}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Cadastrar
                </Button>
              </Form>
          
        </Card.Body>
      </Card>
    </>
  )
}

export default CadastroCliente