import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import consultaEmpresa from '../../services/empresa/empresa_consulta'
import axios from 'axios'


function ContaColuna1() {

  const [empresa, setEmpresa] = useState({
    atividade: '',
    bairro: '',
    celular: '',
    cep: '',
    cidade: '',
    cnpj: '',
    complemento: '',
    email: '',
    empresa: '',
    endereco_programa: '',
    estado: '',
    nome: '',
    numero: '',
    plano: '',
    plataforma: '',
    rua: '',
    telefone: '',
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const empresaData = await consultaEmpresa();
        const empresaWithDefaults = {
          atividade: empresaData.atividade ?? '',
          bairro: empresaData.bairro ?? '',
          celular: empresaData.celular ?? '',
          cep: empresaData.cep ?? '',
          cidade: empresaData.cidade ?? '',
          cnpj: empresaData.cnpj ?? '',
          complemento: empresaData.complemento ?? '',
          email: empresaData.email ?? '',
          empresa: empresaData.empresa ?? '',
          endereco_programa: empresaData.endereco_programa ?? '',
          estado: empresaData.estado ?? '',
          nome: empresaData.nome ?? '',
          numero: empresaData.numero ?? '',
          plano: empresaData.plano ?? '',
          plataforma: empresaData.plataforma ?? '',
          rua: empresaData.rua ?? '',
          telefone: empresaData.telefone ?? '',
        };
        setEmpresa(empresaWithDefaults);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEmpresa((prevEmpresa) => ({
      ...prevEmpresa,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem('token');
    try {
      const data = {
        ...empresa,
        // Convertendo campo 'ramo_de_atividade' para inteiro
        ramo_de_atividade: parseInt(empresa.ramo_de_atividade),
        // Convertendo campo 'como_nos_conheceu' para inteiro
        como_nos_conheceu: parseInt(empresa.como_nos_conheceu),
      };
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      };
      const response = await axios.put(
        `${process.env.REACT_APP_URLBK}/v-1/empresa/conta_editar`,
        data,
        { headers }
      );
      
      if (response.status === 200) {
        alert(response.data.message); // Mensagem de sucesso vinda do Backend
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <div className='icon-text' >
        <FontAwesomeIcon icon={faMoneyBill} size='2x' />
        <h3> Conta</h3>
      </div>
      <Form className='form-empresa' onSubmit={handleFormSubmit}>
        <div className="d-flex justify-content-end align-items-center  align-items-center mb-3">
          <Form.Label className='bold me-2'>Endereço do Programa</Form.Label>
          <Form.Control type="text"
            placeholder="Endereço do Programa"
            disabled
            className='form-empresa-input'
            onChange={handleInputChange}
            value={empresa['endereco_programa']}
            name='endereco_programa'
          />
        </div>
        <div className="d-flex justify-content-end align-items-center  align-items-center mb-3">
          <Form.Label className='bold me-2'>Seu Nome</Form.Label>
          <Form.Control type="text"
            placeholder="Nome"
            className='form-empresa-input'
            onChange={handleInputChange}
            value={empresa['nome']}
            name='nome'
          />
        </div>
        <div className="d-flex justify-content-end align-items-center align-items-center mb-3">
          <Form.Label className='bold me-2'>E-mail</Form.Label>
          <Form.Control type="text"
            placeholder="E-mail"
            className='form-empresa-input'
            onChange={handleInputChange}
            value={empresa['email']}
            name='email'
          />
        </div>
        <div className="d-flex justify-content-end align-items-center align-items-center mb-3" >
          <Form.Label className='bold me-2'>Empresa</Form.Label>
          <Form.Control type="text"
            placeholder="Empresa"
            className='form-empresa-input'
            onChange={handleInputChange}
            disabled
            value={empresa['empresa']}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center align-items-center mb-3">
          <Form.Label className='bold me-2'>Plano</Form.Label>
          <Form.Control type="text"
            placeholder="Plano"
            className='form-empresa-input'
            onChange={handleInputChange}
            value={empresa['plano']}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center align-items-center mb-3">
          <Form.Label className='bold me-2'>Telefone</Form.Label>
          <Form.Control type="text"
            placeholder="Telefone"
            className='form-empresa-input'
            onChange={handleInputChange}
            value={empresa['telefone']}
            name='telefone'
          />
        </div>
        <div className="d-flex justify-content-end align-items-center align-items-center mb-3">
          <Form.Label className='bold me-2'>Celular</Form.Label>
          <Form.Control type="text"
            placeholder="Celular"
            className='form-empresa-input'
            onChange={handleInputChange}
            value={empresa['celular']}
            name='celular'
          />
        </div>
        <div className="d-flex justify-content-end align-items-center align-items-center mb-3">
          <Form.Label className='bold me-2'>CNPJ</Form.Label>
          <Form.Control type="text"
            placeholder="Cnpj"
            className='form-empresa-input'
            onChange={handleInputChange}
            value={empresa['cnpj']}
            name='cnpj'
          />
        </div>
        <div className="d-flex justify-content-end align-items-center align-items-center mb-3">
          <Form.Label className='bold me-2'>CEP</Form.Label>
          <Form.Control type="text"
            placeholder="CEP"
            className='form-empresa-input'
            onChange={handleInputChange}
            value={empresa['cep']}
            name='cep'
          />
        </div>
        <div className="d-flex justify-content-end align-items-center align-items-center mb-3">
          <Form.Label className='bold me-2'>Rua</Form.Label>
          <Form.Control type="text"
            placeholder="Rua"
            className='form-empresa-input'
            onChange={handleInputChange}
            value={empresa['rua']}
            name='rua'
          />
        </div>
        <div className="d-flex justify-content-end align-items-center align-items-center mb-3">
          <Form.Label className='bold me-2'>Número</Form.Label>
          <Form.Control type="text"
            placeholder="Número"
            className='form-empresa-input'
            onChange={handleInputChange}
            value={empresa['numero']}
            name='numero'
          />
        </div>
        <div className="d-flex justify-content-end align-items-center align-items-center mb-3">
          <Form.Label className='bold me-2'>Bairro</Form.Label>
          <Form.Control type="text"
            placeholder="Bairro"
            className='form-empresa-input'
            onChange={handleInputChange}
            value={empresa['bairro']}
            name='bairro'
          />
        </div>
        <div className="d-flex justify-content-end align-items-center align-items-center mb-3">
          <Form.Label className='bold me-2'>Complemento</Form.Label>
          <Form.Control type="text"
            placeholder="Complemento"
            className='form-empresa-input'
            onChange={handleInputChange}
            value={empresa['complemento']}
            name='complemento'
          />
        </div>
        <div className="d-flex justify-content-end align-items-center align-items-center mb-3">
          <Form.Label className='bold me-2'>Estado</Form.Label>
          <Form.Control type="text"
            placeholder="Estado"
            className='form-empresa-input'
            onChange={handleInputChange}
            value={empresa['estado']}
            name='estado'
          />
        </div>
        <div className="d-flex justify-content-end align-items-center align-items-center mb-3">
          <Form.Label className='bold me-2'>Cidade</Form.Label>
          <Form.Control type="text"
            placeholder="Cidade"
            className='form-empresa-input'
            onChange={handleInputChange}
            value={empresa['cidade']}
            name='cidade'
          />
        </div>
        <div className="d-flex justify-content-end align-items-center align-items-center mb-3">
          <Form.Label className='bold me-2'>Ramo de Atividade</Form.Label>
          <Form.Control type="text"
            placeholder="Ramo de Atividade"
            className='form-empresa-input'
            onChange={handleInputChange}
            value={empresa['atividade']}
            name='atividade'
          />
        </div>
        <div className="d-flex justify-content-end align-items-center align-items-center mb-3">
          <Form.Label className='bold me-2'>Como nos conheceu</Form.Label>
          <Form.Control type="text"
            placeholder="Como nos conheceu"
            className='form-empresa-input'
            onChange={handleInputChange}
            value={empresa['plataforma']}
            name='plataforma'
          />
        </div>
        <Button type='submit' className='usuarionovo-button-criar'>Salvar</Button>

      </Form>
    </>
  )
}

export default ContaColuna1;