import axios from 'axios'

const adicionar = async(clienteData) => {
        var dados = {
            "cpf": clienteData.cpf,
            "valor": clienteData.valor
        }

            const token = sessionStorage.getItem('token');
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            };

            const response = await axios.post(
                `${process.env.REACT_APP_URLBK}/v-1/clientes/pontuar_clientes`,
                dados,
                { headers }
            );
            // Aqui você pode tratar a resposta se necessário
           return response
        } 

        
export default adicionar

// const handleAdd = async () => {
        
//     try {
//         var response = adicionar(clienteData)
//         alert('Cliente pontuado com sucesso:', response.data);
//         setClienteData(initialClienteData);
//     } catch (error) {
//         alert('Erro ao pontuar o cliente: ' + error.response.data['error']);
//     }
//     handleClose();
// };