import React from 'react'
import { Button, Card, Col, Row, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faAward, faCartPlus, faStore, faUsers, faUserAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import One from '../../assets/buttons/carinha.png'
import Two from '../../assets/buttons/2.png'
import Three from '../../assets/buttons/porco.png'
import Four from '../../assets/buttons/carrinho.png'
import Five from '../../assets/buttons/ponto.png'
import Six from '../../assets/buttons/caixdefa.png'

function DashboardButtons(props) {
    return (
        <Row className='dashboard-buttons-rows mt-2'>
            <Col>
                <Card className='dashboard-card' onClick={props.showCadastroModal}>
                    <Card.Body>
                        <div className='dashboard-card-icon'>
                            <Image src={One} />
                        </div>
                        <Button className='dashboard-button-novousuario' >Cadastro</Button>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className='dashboard-card ' onClick={() => window.location.href = "/cadastradosClientes"}>
                    <div className='dashboard-card-icon '>
                        <Image src={Two} />
                    </div>
                    <Card.Body>
                        <Button className='dashboard-button-novousuario' >Consumidores</Button>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className='dashboard-card' onClick={() => window.location.href = "/pontuarCliente"}>
                    <div className='dashboard-card-icon '>
                        <Image src={Three} />
                    </div>
                    <Card.Body>
                        <Button className='dashboard-button-novousuario' >Pontuar</Button>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className='dashboard-card'>
                    <div className='dashboard-card-icon '>
                        <Image src={Four} />
                    </div>
                    <Card.Body>
                        <Button className='dashboard-button-novousuario'>Produtos</Button>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className='dashboard-card' onClick={() => window.open("/pontoDigital", "_blank")}>
                    <div className='dashboard-card-icon ' >
                        <Image src={Five} />
                    </div>
                    <Card.Body>
                        <Button className='dashboard-button-novousuario' >Ponto Digital</Button>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Link to="/caixa" ><Card className='dashboard-card'>
                    <div className='dashboard-card-icon '>
                        <Image src={Six} />
                    </div>
                    <Card.Body>
                        <Button className='dashboard-button-novousuario'>Caixa</Button>
                    </Card.Body>
                </Card></Link>
            </Col>

        </Row>
    )
}

export default DashboardButtons