import React, { useState } from 'react'
import LogoGenerica from '../../assets/logo.png'
import LogoCP from '../../assets/logo-cp.png'
import axios from 'axios'
import { Alert } from 'react-bootstrap'
import InputMask from 'react-input-mask';

function Login() {

    const [usuario, setUsuario] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)

    const handleSubmit = () => {
        axios.post(`${process.env.REACT_APP_URLBK}/v-1/clientes/login/`, { "cpf": usuario, "senha": password }, {
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",

        })
            .then(response => {
                sessionStorage.setItem("token", response.data['token'])
                sessionStorage.setItem("cpf", response.data['cliente']['cpf'])
                sessionStorage.setItem("nome", response.data['cliente']['nome'])
                window.location = "/cliente/areadocliente"

            })
            .catch(error => {
                setError(true)
                // Lida com erros da requisição
            });
    }




    return (
        <div className='container-login'>
            <div className='logo-login-cp-header'>
                <img src={LogoCP} className='logo-login-cp-img' />
            </div>
            <div className='login-desktop-div'>
                <div className='login-desktop-prime'>
                    <h2>"Venha Viver a Experiência Prime"</h2>
                </div>
                <div className='login-desktop-prime-2'>
                    <div>
                        <img src={LogoGenerica} className='logo-login-img' />
                    </div>
                    <div className=" mt-5 card-form-login">
                        <div>
                            {error === true ?
                                < Alert key="danger" variant="danger">
                                    Usuario e/ou Senha Invalida!
                                </Alert> : ""
                            }
                            <h2>Acesse sua conta</h2>
                            <div className="form-group">
                                <label htmlFor="nickName">CPF</label>
                            <InputMask
                                mask="999.999.999-99"
                                maskChar=" "
                                className="form-control mb-3"
                                name="cpf"
                                id="nickName"
                                placeholder="Digite o CPF do cliente"
                                value={usuario}
                                    onChange={e => setUsuario(e.target.value)}
                                required
                            />
                            </div>
                            <div className="form-group">

                                <label htmlFor="password">Senha</label>
                                <input
                                    type="password"
                                    className="form-control mb-3"
                                    id="password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </div>
                            <button type="submit" className="btn button-prime-orange" onClick={handleSubmit}>Entrar</button>
                            <a href='#'><p>Esqueceu sua senha?</p></a>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default Login